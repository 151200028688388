import React, { useEffect } from "react";
import Macy from "macy";
import wysokieObcasyExtra7 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-7.png";
import kMag34 from "../assets/gallery/K_MAG-34.png";
import blackRidingHood2 from "../assets/gallery/BLACKRIDINGHOOD-2.png";
import kMag44 from "../assets/gallery/K_MAG-44.png";
import elizaJakubSzymanski1 from "../assets/gallery/Eliza_Jakub_Szymanski-1.jpg";
import allMinimal from "../assets/gallery/ALLMINIMAL .png";
import blackRidingHood6 from "../assets/gallery/BLACKRIDINGHOOD-6.png";
import wysokieObcasyExtra8 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-8.png";
import wysokieObcasyExtra9 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-9.png";
import kMag36 from "../assets/gallery/K_MAG-36.png";
import dreamers20 from "../assets/gallery/DREAMERS - 20.png";
import kMag1 from "../assets/gallery/K_MAG-1.png";
import kMag2 from "../assets/gallery/K_MAG-2.png";
import kMag3 from "../assets/gallery/K_MAG-3.png";
import kMag4 from "../assets/gallery/K_MAG-4.png";
import unthatchCampaign1 from "../assets/gallery/UNTHATCH_CAMPAIGN-1.png";
import wysokieObcasyExtra1 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-1.png";
import kMag6 from "../assets/gallery/K_MAG-6.png";
import kMag5 from "../assets/gallery/K_MAG-5.png";
import nastyMagazine1 from "../assets/gallery/NASTY_Magazine-1.png";
import nastyMagazine2 from "../assets/gallery/NASTY_Magazine-2.png";
import kMag7 from "../assets/gallery/K_MAG-7.png";
import anonFashionMagazine1 from "../assets/gallery/ANON_Fashion_Magazine-1.png";
import kMag9 from "../assets/gallery/K_MAG-9.png";
import elizaJakubSzymanski4 from "../assets/gallery/Eliza_Jakub_Szymanski-4.png";
import kMag10 from "../assets/gallery/K_MAG-10.png";
import kMag8 from "../assets/gallery/K_MAG-8.png";
import wysokieObcasyExtra10 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-10.png";
import harpersBazaarUkraine2 from "../assets/gallery/Harper’s_Bazaar_Ukraine-2.png";
import theFlowHouse1 from "../assets/gallery/The_Flow_House-1.png";
import harpersBazaarUkraine3 from "../assets/gallery/Harper’s_Bazaar_Ukraine-3.png";
import kMag11 from "../assets/gallery/K_MAG-11.png";
import nastyMagazine3 from "../assets/gallery/NASTY_Magazine-3.png";
import nastyMagazine5 from "../assets/gallery/NASTY_Magazine-5.png";
import harpersBazaarUkraine4 from "../assets/gallery/Harper’s_Bazaar_Ukraine-4.png";
import nastyMagazine4 from "../assets/gallery/NASTY_Magazine-4.png";
import theFlowHouse2 from "../assets/gallery/The_Flow_House-2.png";
import nastyMagazine7 from "../assets/gallery/NASTY_Magazine-7.png";
import kMag12 from "../assets/gallery/K_MAG-12.png";
import nastyMagazine6 from "../assets/gallery/NASTY_Magazine-6.png";
import wysokieObcasyExtra11 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-11.png";
import harpersBazaarUkraine5 from "../assets/gallery/Harper’s_Bazaar_Ukraine-5.png";
import anonFashionMagazine2 from "../assets/gallery/ANON_Fashion_Magazine-2.png";
import nastyMagazine8 from "../assets/gallery/NASTY_Magazine-8.png";
import anonFashionMagazine3 from "../assets/gallery/ANON_Fashion_Magazine-3.png";
import nastyMagazine9 from "../assets/gallery/NASTY_Magazine-9.png";
import maya1 from "../assets/gallery/MAYA-1.png";
import forbesWomenPolska1 from "../assets/gallery/Forbes_Women_Polska-1.png";
import kMag13 from "../assets/gallery/K_MAG-13.png";
import nastyMagazine10 from "../assets/gallery/NASTY_Magazine-10.png";
import anonFashionMagazine4 from "../assets/gallery/ANON_Fashion_Magazine-4.png";
import nastyMagazine11 from "../assets/gallery/NASTY_Magazine-11.png";
import latestMagazine2 from "../assets/gallery/LATEST_Magazine-2.png";
import latestMagazine1 from "../assets/gallery/LATEST_Magazine-1.png";
import kMag14 from "../assets/gallery/K_MAG-14.png";
import nastyMagazine13 from "../assets/gallery/NASTY_Magazine-13.png";
import kMag15 from "../assets/gallery/K_MAG-15.png";
import nastyMagazine12 from "../assets/gallery/NASTY_Magazine-12.png";
import nastyMagazine14 from "../assets/gallery/NASTY_Magazine-14.png";
import elizaJakubSzymanski5 from "../assets/gallery/Eliza_Jakub_Szymanski-5.png";
import lOfficielBaltic1 from "../assets/gallery/L’Officiel_Baltic-1.png";
import hairCampaign1 from "../assets/gallery/HAIR_CAMPAIGN-1.png";
import kMag37 from "../assets/gallery/K_MAG-37.png";
import nunthatchCampaign1 from "../assets/gallery/NUNTHATCH_CAMPAIGN-1.png";
import hairCampaign2 from "../assets/gallery/HAIR_CAMPAIGN-2.png";
import lOfficielBaltic2 from "../assets/gallery/L’Officiel_Baltic-2.png";
import nunthatchCampaign2 from "../assets/gallery/NUNTHATCH_CAMPAIGN-2.png";
import vintageConcept22 from "../assets/gallery/VINTAGECONCEPT-22.png";
import nunthatchCampaign8 from "../assets/gallery/NUNTHATCH_CAMPAIGN-8.png";
import nunthatchCampaign4 from "../assets/gallery/NUNTHATCH_CAMPAIGN-4.png";
import elizaJakubSzymanski2 from "../assets/gallery/Eliza_Jakub_Szymanski-2.jpg";
import hairCampaign3 from "../assets/gallery/HAIR_CAMPAIGN-3.png";
import wysokieObcasyExtra12 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-12.png";
import nunthatchCampaign5 from "../assets/gallery/NUNTHATCH_CAMPAIGN-5.png";
import kMag17 from "../assets/gallery/K_MAG-17.png";
import nunthatchCampaign3 from "../assets/gallery/NUNTHATCH_CAMPAIGN-3.png";
import kMag16 from "../assets/gallery/K_MAG-16.png";
import anonFashionMagazine5 from "../assets/gallery/ANON_Fashion_Magazine-5.png";
import harpersBazaarUkraine6 from "../assets/gallery/Harper’s_Bazaar_Ukraine-6.png";
import kMag38 from "../assets/gallery/K_MAG-38.png";
import hairCampaign4 from "../assets/gallery/HAIR_CAMPAIGN-4.png";
import kMag18 from "../assets/gallery/K_MAG-18.png";
import nunthatchCampaign6 from "../assets/gallery/NUNTHATCH_CAMPAIGN-6.png";
import kMag39 from "../assets/gallery/K_MAG-39.png";
import nastyMagazine15 from "../assets/gallery/NASTY_Magazine-15.png";
import kMag19 from "../assets/gallery/K_MAG-19.png";
import theFlowHouse3 from "../assets/gallery/The_Flow_House-3.png";
import nunthatchCampaign7 from "../assets/gallery/NUNTHATCH_CAMPAIGN-7.png";
import kMag21 from "../assets/gallery/K_MAG-21.png";
import kMag20 from "../assets/gallery/K_MAG-20.png";
import kMag22 from "../assets/gallery/K_MAG-22.png";
import theFlowHouse4 from "../assets/gallery/The_Flow_House-4.png";
import wysokieObcasy1 from "../assets/gallery/WYSOKIE_OBCASY-1.png";
import lOfficielBaltic3 from "../assets/gallery/L’Officiel_Baltic-3.png";
import lOfficielBaltic4 from "../assets/gallery/L’Officiel_Baltic-4.png";
import elizaJakubSzymanski3 from "../assets/gallery/Eliza_Jakub_Szymanski-3.jpg";
import kMag43 from "../assets/gallery/K_MAG-43.png";
import kMag23 from "../assets/gallery/K_MAG-23.png";
import anonFashionMagazine8 from "../assets/gallery/ANON_Fashion_Magazine-8.png";
import lOfficielBaltic5 from "../assets/gallery/L’Officiel_Baltic-5.png";
import wysokieObcasyExtra2 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-2.png";
import kMag24 from "../assets/gallery/K_MAG-24.png";
import vintageThinking1 from "../assets/gallery/VINTAGE THINKING -1.png";
import wysokieObcasyExtra13 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-13.png";
import wysokieObcasy2 from "../assets/gallery/WYSOKIE_OBCASY-2.png";
import hairCampaign5 from "../assets/gallery/HAIR_CAMPAIGN-5.png";
import wysokieObcasyExtra3 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-3.png";
import wysokieObcasyExtra4 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-4.png";
import lOfficielBaltics1 from "../assets/gallery/L'Officiel Baltics-1.png";
import shapeOfLove6 from "../assets/gallery/SHAPEOFLOVE-6.png";
import lOfficielBaltics2 from "../assets/gallery/L'Officiel Baltics-2.png";
import vintageThinking21 from "../assets/gallery/VINTAGE THINKING -21.png";
import kMag40 from "../assets/gallery/K_MAG-40.png";
import dreamers8 from "../assets/gallery/DREAMERS-8.png";
import shapeOfLove20 from "../assets/gallery/SHAPEOFLOVE-20.png";
import vintageConcept5 from "../assets/gallery/VINTAGECONCEPT-5.png";
import vintageConcept21 from "../assets/gallery/VINTAGECONCEPT-21.png";
import vintageConcept20 from "../assets/gallery/VINTAGECONCEPT-20.png";
import shapeOfLove5 from "../assets/gallery/SHAPEOFLOVE-5.png";
import meller1 from "../assets/gallery/MELLER-1.png";
import kMag41 from "../assets/gallery/K_MAG-41.png";
import shapeOfLove4 from "../assets/gallery/SHAPEOFLOVE-4.png";
import vintageConcept24 from "../assets/gallery/VINTAGECONCEPT-24.png";
import vintageThinking6 from "../assets/gallery/VINTAGE THINKING -6.png";
import vintageConcept23 from "../assets/gallery/VINTAGECONCEPT-23.png";
import vintageThinking20 from "../assets/gallery/VINTAGE THINKING -20.png";
import meller2 from "../assets/gallery/MELLER-2.png";
import vintageThinking4 from "../assets/gallery/VINTAGE THINKING -4.png";
import kMag42 from "../assets/gallery/K_MAG-42.png";
import anonFashionMagazine9 from "../assets/gallery/ANON_Fashion_Magazine-9.png";
import vintageThinking3 from "../assets/gallery/VINTAGE THINKING -3.png";

import koteluk1 from "../assets/gallery/KOTELUK.1.png";
import koteluk2 from "../assets/gallery/KOTELUK.2.png";
import koteluk3 from "../assets/gallery/KOTELUK.3.png";
import koteluk4 from "../assets/gallery/KOTELUK.4.png";
import koteluk5 from "../assets/gallery/KOTELUK.5.png";
import koteluk6 from "../assets/gallery/KOTELUK.6.png";
import koteluk7 from "../assets/gallery/KOTELUK.7.png";
import lsnienie1 from "../assets/gallery/LŚNIENIE.1.png";
import lsnienie2 from "../assets/gallery/LŚNIENIE.2.png";
import lsnienie3 from "../assets/gallery/LŚNIENIE.3.png";
import lsnienie4 from "../assets/gallery/LŚNIENIE.4.png";
import lsnienie5 from "../assets/gallery/LŚNIENIE.5.png";
import lsnienie6 from "../assets/gallery/LŚNIENIE.6.png";
import lsnienie7 from "../assets/gallery/LŚNIENIE.7.png";
import lsnienie8 from "../assets/gallery/LŚNIENIE.8.png";
import lsnienie9 from "../assets/gallery/LŚNIENIE.9.png";
import silence1 from "../assets/gallery/SILENCE.1.png";
import silence2 from "../assets/gallery/SILENCE.2.png";
import silence3 from "../assets/gallery/SILENCE.3.png";
import silence4 from "../assets/gallery/SILENCE.4.png";
import silence5 from "../assets/gallery/SILENCE.5.png";
import silence6 from "../assets/gallery/SILENCE.6.png";
import silence7 from "../assets/gallery/SILENCE.7.png";
import silence8 from "../assets/gallery/SILENCE.8.png";
import pepe1 from "../assets/gallery/PEPE.1.png";
import pepe2 from "../assets/gallery/PEPE.2.png";
import kaczmarek1 from "../assets/gallery/kaczmarek.1.png";
import kaczmarek2 from "../assets/gallery/kaczmarek.2.png";
import bochniarz1 from "../assets/gallery/BOCHNIARZ.1.png";
import ca1 from "../assets/gallery/CA.1.png";
import ca2 from "../assets/gallery/CA.2.png";
import weeds1 from "../assets/gallery/WEEDS.1.png";

const galleryItems = [
  {
    src: wysokieObcasyExtra7,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: lsnienie1,
    alt: "LŚNIENIE MAGAZYN | HARMONY",
    title: "LŚNIENIE MAGAZYN | HARMONY",
  },
  {
    src: koteluk1,
    alt: "MELA KOTELUK",
    title: "MELA KOTELUK",
  },
  { src: kMag44, alt: "K MAG", title: "K MAG" },
  {
    src: blackRidingHood2,
    alt: "BLACK RIDING HOOD",
    title: "BLACK RIDING HOOD",
  },
  { src: kMag34, alt: "K MAG", title: "K MAG" },
  {
    src: elizaJakubSzymanski1,
    alt: "Personal Project | Out",
    title: "Personal Project | Out",
  },
  {
    src: koteluk2,
    alt: "MELA KOTELUK",
    title: "MELA KOTELUK",
  },
  {
    src: blackRidingHood6,
    alt: "BLACK RIDING HOOD",
    title: "BLACK RIDING HOOD",
  },
  {
    src: allMinimal,
    alt: "Harpers’s Bazar Ukraine | ALL MINIMAL",
    title: "Harpers’s Bazar Ukraine | ALL MINIMAL",
  },
  {
    src: wysokieObcasyExtra8,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: wysokieObcasyExtra9,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: lsnienie2,
    alt: "LŚNIENIE MAGAZYN | HARMONY",
    title: "LŚNIENIE MAGAZYN | HARMONY",
  },
  {
    src: koteluk3,
    alt: "MELA KOTELUK",
    title: "MELA KOTELUK",
  },
  {
    src: lsnienie3,
    alt: "LŚNIENIE MAGAZYN | HARMONY",
    title: "LŚNIENIE MAGAZYN | HARMONY",
  },
  { src: kMag36, alt: "K MAG | SINK OR SWIM", title: "K MAG | SINK OR SWIM" },
  {
    src: silence1,
    alt: "ANON Fashion Magazine | IN SILENCE",
    title: "ANON Fashion Magazine | IN SILENCE",
  },
  {
    src: dreamers20,
    alt: "L’Officiel Baltic | DREAMERS",
    title: "L’Officiel Baltic | DREAMERS",
  },
  {
    src: lsnienie4,
    alt: "LŚNIENIE MAGAZYN | HARMONY",
    title: "LŚNIENIE MAGAZYN | HARMONY",
  },
  {
    src: kMag1,
    alt: "K MAG | Artificial Attributes",
    title: "K MAG | Artificial Attributes",
  },
  {
    src: kMag2,
    alt: "K MAG | Artificial Attributes",
    title: "K MAG | Artificial Attributes",
  },
  {
    src: kMag3,
    alt: "K MAG | Artificial Attributes",
    title: "K MAG | Artificial Attributes",
  },
  {
    src: pepe1,
    alt: "PATRIZIA PEPE x Wysokie Obcasy Extra",
    title: "PATRIZIA PEPE x Wysokie Obcasy Extra",
  },
  {
    src: kMag4,
    alt: "K MAG | Artificial Attributes",
    title: "K MAG | Artificial Attributes",
  },
  {
    src: unthatchCampaign1,
    alt: "NUNTHATCH CAMPAIGN",
    title: "NUNTHATCH CAMPAIGN",
  },
  {
    src: wysokieObcasyExtra1,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: pepe2,
    alt: "PATRIZIA PEPE x Wysokie Obcasy Extra",
    title: "PATRIZIA PEPE x Wysokie Obcasy Extra",
  },
  {
    src: kMag6,
    alt: "K MAG | Artificial Attributes",
    title: "K MAG | Artificial Attributes",
  },
  {
    src: kMag5,
    alt: "K MAG | SECRET GEOMETRY",
    title: "K MAG | SECRET GEOMETRY",
  },
  {
    src: nastyMagazine1,
    alt: "NASTY Magazine | The Hunger",
    title: "NASTY Magazine | The Hunger",
  },
  {
    src: nastyMagazine2,
    alt: "NASTY Magazine | The Hunger",
    title: "NASTY Magazine | The Hunger",
  },
  {
    src: kMag7,
    alt: "K MAG | SECRET GEOMETRY",
    title: "K MAG | SECRET GEOMETRY",
  },
  {
    src: anonFashionMagazine1,
    alt: "ANON Fashion Magazine | BEATA + MAGDA",
    title: "ANON Fashion Magazine | BEATA + MAGDA",
  },
  {
    src: kMag9,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },
  {
    src: elizaJakubSzymanski4,
    alt: "Personal Project | Out",
    title: "Personal Project | Out",
  },
  {
    src: kMag10,
    alt: "K MAG | Artificial Attributes",
    title: "K MAG | Artificial Attributes",
  },
  {
    src: lsnienie5,
    alt: "LŚNIENIE MAGAZYN | HARMONY",
    title: "LŚNIENIE MAGAZYN | HARMONY",
  },
  {
    src: kMag8,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },
  {
    src: wysokieObcasyExtra10,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: harpersBazaarUkraine2,
    alt: "Harper’s Bazaar Ukraine | ALL MINIMAL",
    title: "Harper’s Bazaar Ukraine | ALL MINIMAL",
  },
  {
    src: theFlowHouse1,
    alt: "The Flow House | These Days",
    title: "The Flow House | These Days",
  },
  {
    src: lsnienie6,
    alt: "LŚNIENIE MAGAZYN | HARMONY",
    title: "LŚNIENIE MAGAZYN | HARMONY",
  },
  {
    src: harpersBazaarUkraine3,
    alt: "Harper’s Bazaar Ukraine | ALL MINIMAL",
    title: "Harper’s Bazaar Ukraine | ALL MINIMAL",
  },
  {
    src: kMag11,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },
  {
    src: nastyMagazine3,
    alt: "NASTY Magazine | Rolling",
    title: "NASTY Magazine | Rolling",
  },
  {
    src: nastyMagazine5,
    alt: "NASTY Magazine | Rolling",
    title: "NASTY Magazine | Rolling",
  },
  {
    src: harpersBazaarUkraine4,
    alt: "Harper’s Bazaar Ukraine | ALL MINIMAL",
    title: "Harper’s Bazaar Ukraine | ALL MINIMAL",
  },
  {
    src: nastyMagazine4,
    alt: "NASTY Magazine | The Hunger",
    title: "NASTY Magazine | The Hunger",
  },
  {
    src: theFlowHouse2,
    alt: "The Flow House | These Days",
    title: "The Flow House | These Days",
  },
  {
    src: nastyMagazine7,
    alt: "NASTY Magazine | The Hunger",
    title: "NASTY Magazine | The Hunger",
  },
  {
    src: kaczmarek1,
    alt: "Forbes Women Polska | Natalia Kaczmarek",
    title: "Forbes Women Polska | Natalia Kaczmarek",
  },
  {
    src: kMag12,
    alt: "K MAG | Artificial Attributes",
    title: "K MAG | Artificial Attributes",
  },
  {
    src: koteluk4,
    alt: "MELA KOTELUK",
    title: "MELA KOTELUK",
  },
  {
    src: lsnienie7,
    alt: "LŚNIENIE MAGAZYN | HARMONY",
    title: "LŚNIENIE MAGAZYN | HARMONY",
  },
  {
    src: nastyMagazine6,
    alt: "NASTY Magazine | The Hunger",
    title: "NASTY Magazine | The Hunger",
  },
  {
    src: wysokieObcasyExtra11,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: harpersBazaarUkraine5,
    alt: "Harper’s Bazaar Ukraine | ALL MINIMAL",
    title: "Harper’s Bazaar Ukraine | ALL MINIMAL",
  },
  {
    src: anonFashionMagazine2,
    alt: "ANON Fashion Magazine | BEATA + MAGDA",
    title: "ANON Fashion Magazine | BEATA + MAGDA",
  },
  {
    src: nastyMagazine8,
    alt: "NASTY Magazine | Rolling",
    title: "NASTY Magazine | Rolling",
  },
  {
    src: anonFashionMagazine3,
    alt: "ANON Fashion Magazine | BEATA + MAGDA",
    title: "ANON Fashion Magazine | BEATA + MAGDA",
  },
  {
    src: nastyMagazine9,
    alt: "NASTY Magazine | Rolling",
    title: "NASTY Magazine | Rolling",
  },
  { src: maya1, alt: "MAYA", title: "MAYA" },
  {
    src: koteluk5,
    alt: "MELA KOTELUK",
    title: "MELA KOTELUK",
  },
  {
    src: lsnienie8,
    alt: "LŚNIENIE MAGAZYN | HARMONY",
    title: "LŚNIENIE MAGAZYN | HARMONY",
  },
  {
    src: forbesWomenPolska1,
    alt: "Forbes Women Polska",
    title: "Forbes Women Polska",
  },
  {
    src: kMag13,
    alt: "K MAG | Artificial Attributes",
    title: "K MAG | Artificial Attributes",
  },
  {
    src: nastyMagazine10,
    alt: "NASTY Magazine | Rolling",
    title: "NASTY Magazine | Rolling",
  },
  {
    src: anonFashionMagazine4,
    alt: "ANON Fashion Magazine | BEATA + MAGDA",
    title: "ANON Fashion Magazine | BEATA + MAGDA",
  },
  {
    src: nastyMagazine11,
    alt: "NASTY Magazine | The Hunger",
    title: "NASTY Magazine | The Hunger",
  },
  {
    src: bochniarz1,
    alt: "Forbes Women Polska | Henryka Bochniarz",
    title: "Forbes Women Polska | Henryka Bochniarz",
  },
  {
    src: latestMagazine2,
    alt: "LATEST Magazine | Feminine Freedom",
    title: "LATEST Magazine | Feminine Freedom",
  },
  {
    src: silence2,
    alt: "ANON Fashion Magazine | IN SILENCE",
    title: "ANON Fashion Magazine | IN SILENCE",
  },
  {
    src: ca1,
    alt: "C&A x Wysokie Obcasy Extra",
    title: "C&A x Wysokie Obcasy Extra",
  },
  {
    src: latestMagazine1,
    alt: "LATEST Magazine | Feminine Freedom",
    title: "LATEST Magazine | Feminine Freedom",
  },
  {
    src: kMag14,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },
  {
    src: nastyMagazine13,
    alt: "NASTY Magazine | The Hunger",
    title: "NASTY Magazine | The Hunger",
  },
  {
    src: kMag15,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },
  {
    src: nastyMagazine12,
    alt: "NASTY Magazine | The Hunger",
    title: "NASTY Magazine | The Hunger",
  },
  {
    src: nastyMagazine14,
    alt: "NASTY Magazine | Rolling",
    title: "NASTY Magazine | Rolling",
  },
  {
    src: elizaJakubSzymanski5,
    alt: "Personal Project | Out",
    title: "Personal Project | Out",
  },
  {
    src: lOfficielBaltic1,
    alt: "L’Officiel Baltic | Dancing Queen",
    title: "L’Officiel Baltic | Dancing Queen",
  },
  {
    src: hairCampaign1,
    alt: "HAIR CAMPAIGN by PATRYK BARTYNA",
    title: "HAIR CAMPAIGN by PATRYK BARTYNA",
  },
  { src: kMag37, alt: "K MAG | SINK OR SWIM", title: "K MAG | SINK OR SWIM" },
  {
    src: nunthatchCampaign1,
    alt: "NUNTHATCH CAMPAIGN",
    title: "NUNTHATCH CAMPAIGN",
  },
  {
    src: hairCampaign2,
    alt: "HAIR CAMPAIGN by PATRYK BARTYNA",
    title: "HAIR CAMPAIGN by PATRYK BARTYNA",
  },
  {
    src: lOfficielBaltic2,
    alt: "L’Officiel Baltic | Dancing Queen",
    title: "L’Officiel Baltic | Dancing Queen",
  },
  {
    src: nunthatchCampaign2,
    alt: "NUNTHATCH CAMPAIGN",
    title: "NUNTHATCH CAMPAIGN",
  },
  { src: vintageConcept22, alt: "VINTAGECONCEPT", title: "VINTAGECONCEPT" },
  {
    src: silence3,
    alt: "ANON Fashion Magazine | IN SILENCE",
    title: "ANON Fashion Magazine | IN SILENCE",
  },
  {
    src: lsnienie9,
    alt: "LŚNIENIE MAGAZYN | HARMONY",
    title: "LŚNIENIE MAGAZYN | HARMONY",
  },
  {
    src: nunthatchCampaign8,
    alt: "NUNTHATCH CAMPAIGN",
    title: "NUNTHATCH CAMPAIGN",
  },
  {
    src: nunthatchCampaign4,
    alt: "NUNTHATCH CAMPAIGN",
    title: "NUNTHATCH CAMPAIGN",
  },
  {
    src: elizaJakubSzymanski2,
    alt: "Personal Project | Out",
    title: "Personal Project | Out",
  },
  {
    src: hairCampaign3,
    alt: "HAIR CAMPAIGN by PATRYK BARTYNA",
    title: "HAIR CAMPAIGN by PATRYK BARTYNA",
  },
  {
    src: wysokieObcasyExtra12,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: nunthatchCampaign5,
    alt: "NUNTHATCH CAMPAIGN",
    title: "NUNTHATCH CAMPAIGN",
  },
  {
    src: kMag17,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },
  {
    src: nunthatchCampaign3,
    alt: "NUNTHATCH CAMPAIGN",
    title: "NUNTHATCH CAMPAIGN",
  },
  {
    src: weeds1,
    alt: "CONTRIBUTOR MAGAZINE | WEEDS",
    title: "CONTRIBUTOR MAGAZINE | WEEDS",
  },
  {
    src: kMag16,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },
  {
    src: silence4,
    alt: "ANON Fashion Magazine | IN SILENCE",
    title: "ANON Fashion Magazine | IN SILENCE",
  },
  {
    src: ca2,
    alt: "C&A x Wysokie Obcasy Extra",
    title: "C&A x Wysokie Obcasy Extra",
  },
  {
    src: silence5,
    alt: "ANON Fashion Magazine | IN SILENCE",
    title: "ANON Fashion Magazine | IN SILENCE",
  },
  {
    src: anonFashionMagazine5,
    alt: "ANON Fashion Magazine | BEATA + MAGDA",
    title: "ANON Fashion Magazine | BEATA + MAGDA",
  },
  {
    src: harpersBazaarUkraine6,
    alt: "Harper’s Bazaar Ukraine | ALL MINIMAL",
    title: "Harper’s Bazaar Ukraine | ALL MINIMAL",
  },
  { src: kMag38, alt: "K MAG | SINK OR SWIM", title: "K MAG | SINK OR SWIM" },
  {
    src: hairCampaign4,
    alt: "HAIR CAMPAIGN by PATRYK BARTYNA",
    title: "HAIR CAMPAIGN by PATRYK BARTYNA",
  },
  {
    src: kMag18,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },
  {
    src: nunthatchCampaign6,
    alt: "NUNTHATCH CAMPAIGN",
    title: "NUNTHATCH CAMPAIGN",
  },
  { src: kMag39, alt: "K MAG | SINK OR SWIM", title: "K MAG | SINK OR SWIM" },
  {
    src: nastyMagazine15,
    alt: "NASTY Magazine | Rolling",
    title: "NASTY Magazine | Rolling",
  },
  {
    src: kMag19,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },
  {
    src: theFlowHouse3,
    alt: "The Flow House | These Days",
    title: "The Flow House | These Days",
  },
  {
    src: nunthatchCampaign7,
    alt: "NUNTHATCH CAMPAIGN",
    title: "NUNTHATCH CAMPAIGN",
  },
  {
    src: kMag21,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },

  {
    src: silence6,
    alt: "ANON Fashion Magazine | IN SILENCE",
    title: "ANON Fashion Magazine | IN SILENCE",
  },
  {
    src: koteluk6,
    alt: "MELA KOTELUK",
    title: "MELA KOTELUK",
  },
  {
    src: silence7,
    alt: "ANON Fashion Magazine | IN SILENCE",
    title: "ANON Fashion Magazine | IN SILENCE",
  },

  {
    src: kMag20,
    alt: "K MAG | Falling at Her Feet",
    title: "K MAG | Falling at Her Feet",
  },
  {
    src: kMag22,
    alt: "K MAG | SECRET GEOMETRY",
    title: "K MAG | SECRET GEOMETRY",
  },
  {
    src: theFlowHouse4,
    alt: "The Flow House | These Days",
    title: "The Flow House | These Days",
  },
  { src: wysokieObcasy1, alt: "WYSOKIE OBCASY", title: "WYSOKIE OBCASY" },
  {
    src: lOfficielBaltic3,
    alt: "L’Officiel Baltic | Dancing Queen",
    title: "L’Officiel Baltic | Dancing Queen",
  },
  {
    src: lOfficielBaltic4,
    alt: "L’Officiel Baltic | Dancing Queen",
    title: "L’Officiel Baltic | Dancing Queen",
  },
  {
    src: elizaJakubSzymanski3,
    alt: "Personal Project | Out",
    title: "Personal Project | Out",
  },
  { src: kMag43, alt: "K MAG", title: "K MAG" },
  {
    src: kMag23,
    alt: "K MAG | SECRET GEOMETRY",
    title: "K MAG | SECRET GEOMETRY",
  },
  {
    src: anonFashionMagazine8,
    alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
    title: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
  },
  {
    src: lOfficielBaltic5,
    alt: "L’Officiel Baltic | Dancing Queen",
    title: "L’Officiel Baltic | Dancing Queen",
  },
  {
    src: wysokieObcasyExtra2,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: kMag24,
    alt: "K MAG | SECRET GEOMETRY",
    title: "K MAG | SECRET GEOMETRY",
  },
  {
    src: vintageThinking1,
    alt: "ANON Fashion Magazine | VINTAGE THINKING",
    title: "ANON Fashion Magazine | VINTAGE THINKING",
  },
  {
    src: wysokieObcasyExtra13,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  { src: wysokieObcasy2, alt: "WYSOKIE OBCASY", title: "WYSOKIE OBCASY" },
  {
    src: hairCampaign5,
    alt: "HAIR CAMPAIGN by PATRYK BARTYNA",
    title: "HAIR CAMPAIGN by PATRYK BARTYNA",
  },
  {
    src: wysokieObcasyExtra3,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: wysokieObcasyExtra4,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: lOfficielBaltics1,
    alt: "L’Officiel Baltic | DREAMERS",
    title: "L’Officiel Baltic | DREAMERS",
  },
  {
    src: shapeOfLove6,
    alt: "HAIR CAMPAIGN | ARTEGO GLOBAL",
    title: "HAIR CAMPAIGN | ARTEGO GLOBAL",
  },
  {
    src: lOfficielBaltics2,
    alt: "L’Officiel Baltic | DREAMERS",
    title: "L’Officiel Baltic | DREAMERS",
  },
  {
    src: vintageThinking21,
    alt: "ANON Fashion Magazine | VINTAGE THINKING",
    title: "ANON Fashion Magazine | VINTAGE THINKING",
  },
  { src: kMag40, alt: "K MAG", title: "K MAG" },
  {
    src: dreamers8,
    alt: "L’Officiel Baltic | DREAMERS",
    title: "L’Officiel Baltic | DREAMERS",
  },
  {
    src: shapeOfLove20,
    alt: "L’Officiel Baltic | DREAMERS",
    title: "L’Officiel Baltic | DREAMERS",
  },
  { src: vintageConcept5, alt: "VINTAGECONCEPT", title: "VINTAGECONCEPT" },
  { src: vintageConcept21, alt: "VINTAGECONCEPT", title: "VINTAGECONCEPT" },
  { src: vintageConcept20, alt: "VINTAGECONCEPT", title: "VINTAGECONCEPT" },
  {
    src: shapeOfLove5,
    alt: "HAIR CAMPAIGN | ARTEGO GLOBAL",
    title: "HAIR CAMPAIGN | ARTEGO GLOBAL",
  },
  { src: meller1, alt: "Forbes Women Polska", title: "Forbes Women Polska" },
  { src: kMag41, alt: "K MAG | SINK OR SWIM", title: "K MAG | SINK OR SWIM" },
  {
    src: koteluk7,
    alt: "MELA KOTELUK",
    title: "MELA KOTELUK",
  },
  {
    src: shapeOfLove4,
    alt: "HAIR CAMPAIGN | ARTGEO GLOBAL",
    title: "HAIR CAMPAIGN | ARTGEO GLOBAL",
  },
  { src: vintageConcept24, alt: "VINTAGECONCEPT", title: "VINTAGECONCEPT" },
  {
    src: vintageThinking6,
    alt: "ANON Fashion Magazine | VINTAGE THINKING",
    title: "ANON Fashion Magazine | VINTAGE THINKING",
  },
  { src: vintageConcept23, alt: "VINTAGECONCEPT", title: "VINTAGECONCEPT" },
  {
    src: vintageThinking20,
    alt: "ANON Fashion Magazine | VINTAGE THINKING",
    title: "ANON Fashion Magazine | VINTAGE THINKING",
  },
  { src: meller2, alt: "Forbes Women Polska", title: "Forbes Women Polska" },
  {
    src: vintageThinking4,
    alt: "ANON Fashion Magazine | VINTAGE THINKING",
    title: "ANON Fashion Magazine | VINTAGE THINKING",
  },
  {
    src: kaczmarek2,
    alt: "Forbes Women Polska | Natalia Kaczmarek",
    title: "Forbes Women Polska | Natalia Kaczmarek",
  },
  {
    src: silence8,
    alt: "ANON Fashion Magazine | IN SILENCE",
    title: "ANON Fashion Magazine | IN SILENCE",
  },
  { src: kMag42, alt: "K MAG", title: "K MAG" },
  {
    src: anonFashionMagazine9,
    alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
    title: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
  },
  {
    src: vintageThinking3,
    alt: "ANON Fashion Magazine | VINTAGE THINKING",
    title: "ANON Fashion Magazine | VINTAGE THINKING",
  },
];

const Gallery = () => {
  useEffect(() => {
    const masonry = new Macy({
      container: ".column",
      mobileFirst: true,
      waitForImages: false,
      columns: 2,
      margin: {
        x: 4,
        y: 4,
      },
      breakAt: {
        757: {
          margin: {
            x: 14,
            y: 14,
          },
          columns: 3,
        },
        1440: {
          margin: {
            x: 14,
            y: 14,
          },
          columns: 4,
        },
        1620: {
          margin: {
            x: 14,
            y: 14,
          },
          columns: 5,
        },
      },
    });
  }, []);

  return (
    <section className="gallery">
      <ul className="column">
        {galleryItems.map((item, index) => (
          <li className="item" key={index}>
            <img src={item.src} alt={item.alt} />
            <span>{item.title}</span>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Gallery;
