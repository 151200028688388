// src/components/Portfolio.js

import React from "react";
import { Link } from "react-router-dom";
import commercialIcon from "../assets/portfolio/commercial_lazar 1.png";
import movieIcon from "../assets/portfolio/movie_lazar 2.png";
import fashionShowIcon from "../assets/portfolio/fashionshow_lazar.png";
import fashionIcon from "../assets/portfolio/fashion_lazar.png";
import logo from "../assets/img/logo_stefanialazar_R 2.png";

const Portfolio = () => {
  return (
    <section className="content --orange-bg">
      <section className="portfolio-main">
        <div className="portfolio-menu">
          <ul>
            <li>
              <Link to="/commercial" title="Comercial">
                <img src={commercialIcon} alt="Comercial" />
              </Link>
            </li>
            <li>
              <Link to="/movie" title="Movie">
                <img src={movieIcon} alt="Movie" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="logo --portfolio">
          <div className="logo__container">
            <Link to="/" className="logo__link">
              <img src={logo} alt="Lazar Stefania" />
            </Link>
          </div>
        </div>
        <div className="portfolio-menu">
          <ul>
            <li>
              <Link to="/fashion_show" title="Fashion Show">
                <img src={fashionShowIcon} alt="Fashion Show" />
              </Link>
            </li>
            <li>
              <Link to="/fashion" title="Fashion">
                <img src={fashionIcon} alt="Fashion" />
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </section>
  );
};

export default Portfolio;
