import React, { useEffect, useState } from "react";
import Macy from "macy";
import leftArrow from "../assets/img/left-arrow.png";
import portfolioIcon from "../assets/portfolio/portfolio_lazar 1.png";
import fashionIcon from "../assets/portfolio/fashion_lazar.png";

import elizaJakubSzymanski6 from "../assets/gallery/Eliza_Jakub_Szymanski-6.jpg";
import elizaJakubSzymanski7 from "../assets/gallery/Eliza_Jakub_Szymanski-7.jpg";
import elizaJakubSzymanski8 from "../assets/gallery/Eliza_Jakub_Szymanski-8.jpg";
import elizaJakubSzymanski1 from "../assets/gallery/Eliza_Jakub_Szymanski-1.jpg";
import elizaJakubSzymanski3 from "../assets/gallery/Eliza_Jakub_Szymanski-3.jpg";
import elizaJakubSzymanski9 from "../assets/gallery/Eliza_Jakub_Szymanski-9.jpg";
import elizaJakubSzymanski2 from "../assets/gallery/Eliza_Jakub_Szymanski-2.jpg";

import kMag43 from "../assets/gallery/K_MAG-43.png";
import kMag46 from "../assets/gallery/K_MAG-46.png";
import kMag47 from "../assets/gallery/K_MAG-47.png";
import kMag48 from "../assets/gallery/K_MAG-48.png";
import kMag42 from "../assets/gallery/K_MAG-42.png";
import kMag34 from "../assets/gallery/K_MAG-34.png";
import kMag40 from "../assets/gallery/K_MAG-40.png";
import kMag49 from "../assets/gallery/K_MAG-49.png";
import kMag35 from "../assets/gallery/K_MAG-35.png";
import kMag50 from "../assets/gallery/K_MAG-50.png";

import wysokieObcasyExtra8 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-8.png";
import wysokieObcasyExtra13 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-13.png";
import wysokieObcasyExtra20 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-20.png";
import wysokieObcasyExtra21 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-21.png";

import wysokieObcasyExtra10 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-10.png";
import wysokieObcasyExtra7 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-7.png";
import wysokieObcasyExtra11 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-11.png";
import wysokieObcasyExtra12 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-12.png";

import kMag45 from "../assets/gallery/K_MAG-45.png";
import kMag53 from "../assets/gallery/K_MAG-53.png";
import kMag36 from "../assets/gallery/K_MAG-36.png";
import kMag37 from "../assets/gallery/K_MAG-37.png";
import kMag38 from "../assets/gallery/K_MAG-38.png";
import kMag51 from "../assets/gallery/K_MAG-51.png";
import kMag39 from "../assets/gallery/K_MAG-39.png";
import kMag52 from "../assets/gallery/K_MAG-52.png";
import kMag41 from "../assets/gallery/K_MAG-41.png";

import anonFashionMagazine8 from "../assets/gallery/ANON_Fashion_Magazine-8.png";
import anonFashionMagazine10 from "../assets/gallery/ANON_Fashion_Magazine-10.png";
import anonFashionMagazine11 from "../assets/gallery/ANON_Fashion_Magazine-11.png";
import anonFashionMagazine12 from "../assets/gallery/ANON_Fashion_Magazine-12.png";
import anonFashionMagazine13 from "../assets/gallery/ANON_Fashion_Magazine-13.png";
import anonFashionMagazine14 from "../assets/gallery/ANON_Fashion_Magazine-14.png";
import anonFashionMagazine15 from "../assets/gallery/ANON_Fashion_Magazine-15.png";
import anonFashionMagazine16 from "../assets/gallery/ANON_Fashion_Magazine-16.png";
import anonFashionMagazine17 from "../assets/gallery/ANON_Fashion_Magazine-17.png";
import anonFashionMagazine18 from "../assets/gallery/ANON_Fashion_Magazine-18.png";

import vintageConcept1 from "../assets/gallery/VINTAGECONCEPT-1.png";
import vintageConcept2 from "../assets/gallery/VINTAGECONCEPT-2.png";
import vintageConcept3 from "../assets/gallery/VINTAGECONCEPT-3.png";
import vintageConcept5 from "../assets/gallery/VINTAGECONCEPT-5.png";
import vintageConcept4 from "../assets/gallery/VINTAGECONCEPT-4.png";
import vintageConcept6 from "../assets/gallery/VINTAGECONCEPT-6.png";
import vintageConcept7 from "../assets/gallery/VINTAGECONCEPT-7.png";
import vintageConcept8 from "../assets/gallery/VINTAGECONCEPT-8.png";

import blackRidingHood1 from "../assets/gallery/BLACKRIDINGHOOD-1.png";
import blackRidingHood2 from "../assets/gallery/BLACKRIDINGHOOD-2.png";
import blackRidingHood3 from "../assets/gallery/BLACKRIDINGHOOD-3.png";
import blackRidingHood4 from "../assets/gallery/BLACKRIDINGHOOD-4.png";
import blackRidingHood5 from "../assets/gallery/BLACKRIDINGHOOD-5.png";
import blackRidingHood6 from "../assets/gallery/BLACKRIDINGHOOD-6.png";
import blackRidingHood7 from "../assets/gallery/BLACKRIDINGHOOD-7.png";
import blackRidingHood8 from "../assets/gallery/BLACKRIDINGHOOD-8.png";
import blackRidingHood9 from "../assets/gallery/BLACKRIDINGHOOD-9.png";
import blackRidingHood10 from "../assets/gallery/BLACKRIDINGHOOD-10.png";

import vintageThinking1 from "../assets/gallery/VINTAGE THINKING -1.png";
import vintageThinking2 from "../assets/gallery/VINTAGE THINKING -2.png";
import vintageThinking3 from "../assets/gallery/VINTAGE THINKING -3.png";
import vintageThinking4 from "../assets/gallery/VINTAGE THINKING -4.png";
import vintageThinking5 from "../assets/gallery/VINTAGE THINKING -5.png";
import vintageThinking6 from "../assets/gallery/VINTAGE THINKING -6.png";
import vintageThinking7 from "../assets/gallery/VINTAGE THINKING -7.png";

import lOfficielBaltic5 from "../assets/gallery/L’Officiel_Baltic-5.png";
import lOfficielBaltic7 from "../assets/gallery/L’Officiel_Baltic-7.png";
import lOfficielBaltic4 from "../assets/gallery/L’Officiel_Baltic-4.png";
import lOfficielBaltic8 from "../assets/gallery/L’Officiel_Baltic-8.png";
import lOfficielBaltic2 from "../assets/gallery/L’Officiel_Baltic-2.png";
import lOfficielBaltic10 from "../assets/gallery/L’Officiel_Baltic-10.png";
import lOfficielBaltic9 from "../assets/gallery/L’Officiel_Baltic-9.png";
import lOfficielBaltic3 from "../assets/gallery/L’Officiel_Baltic-3.png";
import lOfficielBaltic11 from "../assets/gallery/L’Officiel_Baltic-11.png";
import lOfficielBaltic1 from "../assets/gallery/L’Officiel_Baltic-1.png";

import kMag25 from "../assets/gallery/K_MAG-25.png";
import kMag24 from "../assets/gallery/K_MAG-24.png";
import kMag22 from "../assets/gallery/K_MAG-22.png";
import kMag26 from "../assets/gallery/K_MAG-26.png";
import kMag23 from "../assets/gallery/K_MAG-23.png";
import kMag5 from "../assets/gallery/K_MAG-5.png";
import kMag27 from "../assets/gallery/K_MAG-27.png";
import kMag28 from "../assets/gallery/K_MAG-28.png";
import kMag29 from "../assets/gallery/K_MAG-29.png";
import kMag7 from "../assets/gallery/K_MAG-7.png";

import wysokieObcasy1 from "../assets/gallery/WYSOKIE_OBCASY-1.png";
import wysokieObcasy5 from "../assets/gallery/WYSOKIE_OBCASY-5.png";
import wysokieObcasy3 from "../assets/gallery/WYSOKIE_OBCASY-3.png";
import wysokieObcasy2 from "../assets/gallery/WYSOKIE_OBCASY-2.png";

import wysokieObcasyExtra2 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-2.png";
import wysokieObcasyExtra5 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-5.png";
import wysokieObcasyExtra3 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-3.png";
import wysokieObcasyExtra6 from "../assets/gallery/WYSOKIE_OBCASY_EXTRA-6.png";

import nastyMagazine14 from "../assets/gallery/NASTY_Magazine-14.png";
import nastyMagazine8 from "../assets/gallery/NASTY_Magazine-8.png";
import nastyMagazine3 from "../assets/gallery/NASTY_Magazine-3.png";
import nastyMagazine10 from "../assets/gallery/NASTY_Magazine-10.png";
import nastyMagazine9 from "../assets/gallery/NASTY_Magazine-9.png";
import nastyMagazine18 from "../assets/gallery/NASTY_Magazine-18.png";
import nastyMagazine17 from "../assets/gallery/NASTY_Magazine-17.png";
import nastyMagazine16 from "../assets/gallery/NASTY_Magazine-16.png";
import nastyMagazine23 from "../assets/gallery/NASTY_Magazine-23.png";
import nastyMagazine24 from "../assets/gallery/NASTY_Magazine-24.png";
import nastyMagazine22 from "../assets/gallery/NASTY_Magazine-22.png";

import nastyMagazine4 from "../assets/gallery/NASTY_Magazine-4.png";
import nastyMagazine1 from "../assets/gallery/NASTY_Magazine-1.png";
import nastyMagazine11 from "../assets/gallery/NASTY_Magazine-11.png";
import nastyMagazine6 from "../assets/gallery/NASTY_Magazine-6.png";
import nastyMagazine20 from "../assets/gallery/NASTY_Magazine-20.png";
import nastyMagazine13 from "../assets/gallery/NASTY_Magazine-13.png";
import nastyMagazine12 from "../assets/gallery/NASTY_Magazine-12.png";
import nastyMagazine2 from "../assets/gallery/NASTY_Magazine-2.png";
import nastyMagazine25 from "../assets/gallery/NASTY_Magazine-25.png";
import nastyMagazine26 from "../assets/gallery/NASTY_Magazine-26.png";
import nastyMagazine28 from "../assets/gallery/NASTY_Magazine-28.png";
import nastyMagazine27 from "../assets/gallery/NASTY_Magazine-27.png";

import anonFashionMagazine5 from "../assets/gallery/ANON_Fashion_Magazine-5.png";
import anonFashionMagazine2 from "../assets/gallery/ANON_Fashion_Magazine-2.png";
import anonFashionMagazine4 from "../assets/gallery/ANON_Fashion_Magazine-4.png";
import anonFashionMagazine3 from "../assets/gallery/ANON_Fashion_Magazine-3.png";
import anonFashionMagazine1 from "../assets/gallery/ANON_Fashion_Magazine-1.png";
import anonFashionMagazine6 from "../assets/gallery/ANON_Fashion_Magazine-6.png";
import anonFashionMagazine7 from "../assets/gallery/ANON_Fashion_Magazine-7.png";

import kMag14 from "../assets/gallery/K_MAG-14.png";
import kMag30 from "../assets/gallery/K_MAG-30.png";
import kMag9 from "../assets/gallery/K_MAG-9.png";
import kMag31 from "../assets/gallery/K_MAG-31.png";
import kMag33 from "../assets/gallery/K_MAG-33.png";
import kMag8 from "../assets/gallery/K_MAG-8.png";
import kMag32 from "../assets/gallery/K_MAG-32.png";
import kMag18 from "../assets/gallery/K_MAG-18.png";
import kMag19 from "../assets/gallery/K_MAG-19.png";
import kMag20 from "../assets/gallery/K_MAG-20.png";
import kMag15 from "../assets/gallery/K_MAG-15.png";
import kMag16 from "../assets/gallery/K_MAG-16.png";
import kMag11 from "../assets/gallery/K_MAG-11.png";

import silence1 from "../assets/gallery/SILENCE.1.png";
import silence2 from "../assets/gallery/SILENCE.2.png";
import silence3 from "../assets/gallery/SILENCE.3.png";
import silence5 from "../assets/gallery/SILENCE.5.png";
import silence6 from "../assets/gallery/SILENCE.6.png";
import silence8 from "../assets/gallery/SILENCE.8.png";
import silence9 from "../assets/gallery/SILENCE.9.png";
import silence10 from "../assets/gallery/SILENCE.10.png";
import silence11 from "../assets/gallery/SILENCE.11.png";
import silence12 from "../assets/gallery/SILENCE.12.png";

import lsnienie1 from "../assets/gallery/LŚNIENIE.1.png";
import lsnienie2 from "../assets/gallery/LŚNIENIE.2.png";
import lsnienie3 from "../assets/gallery/LŚNIENIE.3.png";
import lsnienie5 from "../assets/gallery/LŚNIENIE.5.png";
import lsnienie6 from "../assets/gallery/LŚNIENIE.6.png";
import lsnienie7 from "../assets/gallery/LŚNIENIE.7.png";
import lsnienie10 from "../assets/gallery/LŚNIENIE.10.png";
import lsnienie11 from "../assets/gallery/LŚNIENIE.11.png";
import lsnienie12 from "../assets/gallery/LŚNIENIE.12.png";
import lsnienie13 from "../assets/gallery/LŚNIENIE.13.png";

import bielizna1 from "../assets/gallery/BIELIZNA.1.png";
import bielizna2 from "../assets/gallery/BIELIZNA.2.png";
import bielizna3 from "../assets/gallery/BIELIZNA.3.png";
import bielizna4 from "../assets/gallery/BIELIZNA.4.png";
import bielizna5 from "../assets/gallery/BIELIZNA.5.png";
import bielizna6 from "../assets/gallery/BIELIZNA.6.png";
import bielizna7 from "../assets/gallery/BIELIZNA.7.png";

import koteluk1 from "../assets/gallery/KOTELUK.1.png";
import koteluk2 from "../assets/gallery/KOTELUK.2.png";
import koteluk3 from "../assets/gallery/KOTELUK.3.png";
import koteluk4 from "../assets/gallery/KOTELUK.4.png";
import koteluk5 from "../assets/gallery/KOTELUK.5.png";
import koteluk6 from "../assets/gallery/KOTELUK.6.png";
import koteluk7 from "../assets/gallery/KOTELUK.7.png";
import koteluk8 from "../assets/gallery/KOTELUK.8.png";
import koteluk9 from "../assets/gallery/KOTELUK.9.png";
import koteluk10 from "../assets/gallery/KOTELUK.10.png";
import koteluk11 from "../assets/gallery/KOTELUK.11.png";

import weeds1 from "../assets/gallery/WEEDS.1.png";
import weeds2 from "../assets/gallery/WEEDS.2.png";
import weeds3 from "../assets/gallery/WEEDS.3.png";
import weeds4 from "../assets/gallery/WEEDS.4.png";
import weeds5 from "../assets/gallery/WEEDS.5.png";
import weeds6 from "../assets/gallery/WEEDS.6.png";
import weeds7 from "../assets/gallery/WEEDS.7.png";
import weeds8 from "../assets/gallery/WEEDS.8.png";
import weeds9 from "../assets/gallery/WEEDS.9.png";
import weeds10 from "../assets/gallery/WEEDS.10.png";

const galleryItems = [
  {
    src: silence2,
    alt: "ANON Fashion Magazine | IN SILENCE",
    title: "ANON Fashion Magazine | IN SILENCE",
  },
  {
    src: lsnienie2,
    alt: "LŚNIENIE MAGAZYN | HARMONY",
    title: "LŚNIENIE MAGAZYN | HARMONY",
  },
  {
    src: bielizna1,
    alt: "PANDORA | ZWIERCIADŁO",
    title: "PANDORA | ZWIERCIADŁO",
  },
  {
    src: koteluk7,
    alt: "MELA KOTELUK",
    title: "MELA KOTELUK",
  },
  {
    src: elizaJakubSzymanski6,
    alt: "Personal Project | Out",
    title: "Personal Project | Out",
  },
  { src: kMag43, alt: "K MAG", title: "K MAG" },
  {
    src: wysokieObcasyExtra8,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  {
    src: wysokieObcasyExtra10,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  { src: kMag45, alt: "K MAG | SINK OR SWIM", title: "K MAG | SINK OR SWIM" },
  {
    src: anonFashionMagazine8,
    alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
    title: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
  },
  { src: vintageConcept1, alt: "VINTAGECONCEPT", title: "VINTAGECONCEPT" },
  {
    src: weeds1,
    alt: "CONTRIBUTOR MAGAZINE | WEEDS",
    title: "CONTRIBUTOR MAGAZINE | WEEDS",
  },
  {
    src: blackRidingHood1,
    alt: "BLACK RIDING HOOD",
    title: "BLACK RIDING HOOD",
  },
  {
    src: vintageThinking1,
    alt: "ANON Fashion Magazine | VINTAGE THINKING",
    title: "ANON Fashion Magazine | VINTAGE THINKING",
  },
  {
    src: lOfficielBaltic5,
    alt: "L’Officiel Baltic",
    title: "L’Officiel Baltic",
  },
  { src: kMag25, alt: "K MAG", title: "K MAG" },
  { src: wysokieObcasy1, alt: "WYSOKIE OBCASY", title: "WYSOKIE OBCASY" },
  {
    src: wysokieObcasyExtra2,
    alt: "WYSOKIE OBCASY EXTRA",
    title: "WYSOKIE OBCASY EXTRA",
  },
  { src: nastyMagazine14, alt: "NASTY Magazine", title: "NASTY Magazine" },
  { src: nastyMagazine4, alt: "NASTY Magazine", title: "NASTY Magazine" },
  {
    src: anonFashionMagazine5,
    alt: "ANON Fashion Magazine",
    title: "ANON Fashion Magazine",
  },
  { src: kMag14, alt: "K MAG", title: "K MAG" },
  // Dodaj więcej elementów galerii tutaj
];

const subGalleryItems = [
  {
    title: "ANON Fashion Magazine | IN SILENCE",
    stylist: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    model: "MILENA ZBIŃKOWSKA",
    images: [
      { src: silence2, alt: "ANON Fashion Magazine | IN SILENCE" },
      { src: silence9, alt: "ANON Fashion Magazine | IN SILENCE" },
      { src: silence10, alt: "ANON Fashion Magazine | IN SILENCE" },
      { src: silence5, alt: "ANON Fashion Magazine | IN SILENCE" },
      { src: silence8, alt: "ANON Fashion Magazine | IN SILENCE" },
      { src: silence11, alt: "ANON Fashion Magazine | IN SILENCE" },
      { src: silence12, alt: "ANON Fashion Magazine | IN SILENCE" },
      { src: silence3, alt: "ANON Fashion Magazine | IN SILENCE" },
      { src: silence1, alt: "ANON Fashion Magazine | IN SILENCE" },
      { src: silence6, alt: "ANON Fashion Magazine | IN SILENCE" },
    ],
  },
  {
    title: "LŚNIENIE MAGAZYN | HARMONY",
    stylist: "LAZAR STEFANIA",
    photograph: "ALICJA KOZAK",
    model: "MICHALINA NGUYEN",
    images: [
      { src: lsnienie2, alt: "LŚNIENIE MAGAZYN | HARMONY" },
      { src: lsnienie6, alt: "LŚNIENIE MAGAZYN | HARMONY" },
      { src: lsnienie1, alt: "LŚNIENIE MAGAZYN | HARMONY" },
      { src: lsnienie10, alt: "LŚNIENIE MAGAZYN | HARMONY" },
      { src: lsnienie7, alt: "LŚNIENIE MAGAZYN | HARMONY" },
      { src: lsnienie3, alt: "LŚNIENIE MAGAZYN | HARMONY" },
      { src: lsnienie5, alt: "LŚNIENIE MAGAZYN | HARMONY" },
      { src: lsnienie12, alt: "LŚNIENIE MAGAZYN | HARMONY" },
      { src: lsnienie11, alt: "LŚNIENIE MAGAZYN | HARMONY" },
      { src: lsnienie13, alt: "LŚNIENIE MAGAZYN | HARMONY" },
    ],
  },
  {
    title: "PANDORA | ZWIERCIADŁO",
    stylist: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    images: [
      { src: bielizna1, alt: "PANDORA | ZWIERCIADŁO" },
      { src: bielizna5, alt: "PANDORA | ZWIERCIADŁO" },
      { src: bielizna2, alt: "PANDORA | ZWIERCIADŁO" },
      { src: bielizna7, alt: "PANDORA | ZWIERCIADŁO" },
      { src: bielizna3, alt: "PANDORA | ZWIERCIADŁO" },
      { src: bielizna6, alt: "PANDORA | ZWIERCIADŁO" },
      { src: bielizna4, alt: "PANDORA | ZWIERCIADŁO" },
    ],
  },
  {
    title: "MELA KOTELUK",
    stylist: "LAZAR STEFANIA",
    photograph: "ALEKSANDRA ZABOROWSKA",
    images: [
      { src: koteluk7, alt: "MELA KOTELUK" },
      { src: koteluk1, alt: "MELA KOTELUK" },
      { src: koteluk8, alt: "MELA KOTELUK" },
      { src: koteluk4, alt: "MELA KOTELUK" },
      { src: koteluk9, alt: "MELA KOTELUK" },
      { src: koteluk6, alt: "MELA KOTELUK" },
      { src: koteluk3, alt: "MELA KOTELUK" },
      { src: koteluk2, alt: "MELA KOTELUK" },
      { src: koteluk11, alt: "MELA KOTELUK" },
      { src: koteluk5, alt: "MELA KOTELUK" },
      { src: koteluk10, alt: "MELA KOTELUK" },
    ],
  },
  {
    title: "Personal Project | Out",
    stylist: "LAZAR STEFANIA",
    photograph: "JAKUB SZYMAŃSKI",
    model: "ELIZA KUKAWSKA",
    images: [
      { src: elizaJakubSzymanski6, alt: "Personal Project | Out" },
      { src: elizaJakubSzymanski7, alt: "Personal Project | Out" },
      { src: elizaJakubSzymanski8, alt: "Personal Project | Out" },
      { src: elizaJakubSzymanski1, alt: "Personal Project | Out" },
      { src: elizaJakubSzymanski3, alt: "Personal Project | Out" },
      { src: elizaJakubSzymanski9, alt: "Personal Project | Out" },
      { src: elizaJakubSzymanski2, alt: "Personal Project | Out" },
    ],
  },
  {
    title: "K MAG",
    stylist: "LAZAR STEFANIA",
    photograph: "JAKUB SZYMAŃSKI",
    model: "NATALIA NAPIERALSKA",
    images: [
      { src: kMag43, alt: "K MAG" },
      { src: kMag46, alt: "K MAG" },
      { src: kMag47, alt: "K MAG" },
      { src: kMag48, alt: "K MAG" },
      { src: kMag42, alt: "K MAG" },
      { src: kMag34, alt: "K MAG" },
      { src: kMag40, alt: "K MAG" },
      { src: kMag49, alt: "K MAG" },
      { src: kMag35, alt: "K MAG" },
      { src: kMag50, alt: "K MAG" },
    ],
  },
  {
    title: "WYSOKIE OBCASY EXTRA | MAJKA JEŻOWSKA",
    stylist: "LAZAR STEFANIA",
    photograph: "DAWID GRZELAK",
    model: "MAJKA JEŻOWSKA",
    images: [
      {
        src: wysokieObcasyExtra8,
        alt: "WYSOKIE OBCASY EXTRA | MAJKA JEŻOWSKA",
      },
      {
        src: wysokieObcasyExtra13,
        alt: "WYSOKIE OBCASY EXTRA | MAJKA JEŻOWSKA",
      },
      {
        src: wysokieObcasyExtra20,
        alt: "WYSOKIE OBCASY EXTRA | MAJKA JEŻOWSKA",
      },
      {
        src: wysokieObcasyExtra21,
        alt: "WYSOKIE OBCASY EXTRA | MAJKA JEŻOWSKA",
      },
    ],
  },
  {
    title: "WYSOKIE OBCASY EXTRA | ELIZA RYCEMBAL",
    stylist: "LAZAR STEFANIA",
    photograph: "ALICJA KOZAK",
    model: "ELIZA RYCEMBAL",
    images: [
      {
        src: wysokieObcasyExtra10,
        alt: "WYSOKIE OBCASY EXTRA | ELIZA RYCEMBAL",
      },
      {
        src: wysokieObcasyExtra7,
        alt: "WYSOKIE OBCASY EXTRA | ELIZA RYCEMBAL",
      },
      {
        src: wysokieObcasyExtra11,
        alt: "WYSOKIE OBCASY EXTRA | ELIZA RYCEMBAL",
      },
      {
        src: wysokieObcasyExtra12,
        alt: "WYSOKIE OBCASY EXTRA | ELIZA RYCEMBAL",
      },
    ],
  },
  {
    title: "K MAG | SINK OR SWIM",
    stylist: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    models: "MAJA",
    images: [
      { src: kMag45, alt: "K MAG | SINK OR SWIM" },
      { src: kMag53, alt: "K MAG | SINK OR SWIM" },
      { src: kMag36, alt: "K MAG | SINK OR SWIM" },
      { src: kMag37, alt: "K MAG | SINK OR SWIM" },
      { src: kMag38, alt: "K MAG | SINK OR SWIM" },
      { src: kMag51, alt: "K MAG | SINK OR SWIM" },
      { src: kMag39, alt: "K MAG | SINK OR SWIM" },
      { src: kMag52, alt: "K MAG | SINK OR SWIM" },
      { src: kMag41, alt: "K MAG | SINK OR SWIM" },
    ],
  },
  {
    title: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
    stylist: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    models: "EWA KUROPATWA",
    images: [
      {
        src: anonFashionMagazine8,
        alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
      },
      {
        src: anonFashionMagazine10,
        alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
      },
      {
        src: anonFashionMagazine11,
        alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
      },
      {
        src: anonFashionMagazine12,
        alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
      },
      {
        src: anonFashionMagazine13,
        alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
      },
      {
        src: anonFashionMagazine14,
        alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
      },
      {
        src: anonFashionMagazine15,
        alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
      },
      {
        src: anonFashionMagazine16,
        alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
      },
      {
        src: anonFashionMagazine17,
        alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
      },
      {
        src: anonFashionMagazine18,
        alt: "ANON Fashion Magazine | POSTCARDS FROM EDEN",
      },
    ],
  },
  {
    title: "VINTAGECONCEPT",
    stylist: "LAZAR STEFANIA",
    photograph: "WIKTOR JUNGOWSKI",
    makeupHair: "KASIA OLKOWSKA",
    model: "MARIANKA ZYCH",
    images: [
      { src: vintageConcept1, alt: "VINTAGECONCEPT" },
      { src: vintageConcept2, alt: "VINTAGECONCEPT" },
      { src: vintageConcept3, alt: "VINTAGECONCEPT" },
      { src: vintageConcept5, alt: "VINTAGECONCEPT" },
      { src: vintageConcept4, alt: "VINTAGECONCEPT" },
      { src: vintageConcept6, alt: "VINTAGECONCEPT" },
      { src: vintageConcept7, alt: "VINTAGECONCEPT" },
      { src: vintageConcept8, alt: "VINTAGECONCEPT" },
    ],
  },
  {
    title: "CONTRIBUTOR MAGAZINE | WEEDS",
    stylist: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    model: "JULIA HANCZEWSKA",
    images: [
      { src: weeds1, alt: "CONTRIBUTOR MAGAZINE | WEEDS" },
      { src: weeds7, alt: "CONTRIBUTOR MAGAZINE | WEEDS" },
      { src: weeds8, alt: "CONTRIBUTOR MAGAZINE | WEEDS" },
      { src: weeds6, alt: "CONTRIBUTOR MAGAZINE | WEEDS" },
      { src: weeds10, alt: "CONTRIBUTOR MAGAZINE | WEEDS" },
      { src: weeds3, alt: "CONTRIBUTOR MAGAZINE | WEEDS" },
      { src: weeds9, alt: "CONTRIBUTOR MAGAZINE | WEEDS" },
      { src: weeds4, alt: "CONTRIBUTOR MAGAZINE | WEEDS" },
      { src: weeds5, alt: "CONTRIBUTOR MAGAZINE | WEEDS" },
      { src: weeds2, alt: "CONTRIBUTOR MAGAZINE | WEEDS" },
    ],
  },
  {
    title: "BLACK RIDING HOOD",
    stylist: "LAZAR STEFANIA",
    photograph: "MARCIN SZPAK",
    model: "AGATA TURKOT",
    images: [
      { src: blackRidingHood1, alt: "BLACK RIDING HOOD" },
      { src: blackRidingHood2, alt: "BLACK RIDING HOOD" },
      { src: blackRidingHood3, alt: "BLACK RIDING HOOD" },
      { src: blackRidingHood4, alt: "BLACK RIDING HOOD" },
      { src: blackRidingHood5, alt: "BLACK RIDING HOOD" },
      { src: blackRidingHood6, alt: "BLACK RIDING HOOD" },
      { src: blackRidingHood7, alt: "BLACK RIDING HOOD" },
      { src: blackRidingHood8, alt: "BLACK RIDING HOOD" },
      { src: blackRidingHood9, alt: "BLACK RIDING HOOD" },
      { src: blackRidingHood10, alt: "BLACK RIDING HOOD" },
    ],
  },
  {
    title: "ANON Fashion Magazine | VINTAGE THINKING",
    stylist: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    models: "ASIA PIWKA & MAYA CHUDY & MAGDA MEYER",
    images: [
      {
        src: vintageThinking1,
        alt: "ANON Fashion Magazine | VINTAGE THINKING",
      },
      {
        src: vintageThinking2,
        alt: "ANON Fashion Magazine | VINTAGE THINKING",
      },
      {
        src: vintageThinking3,
        alt: "ANON Fashion Magazine | VINTAGE THINKING",
      },
      {
        src: vintageThinking4,
        alt: "ANON Fashion Magazine | VINTAGE THINKING",
      },
      {
        src: vintageThinking5,
        alt: "ANON Fashion Magazine | VINTAGE THINKING",
      },
      {
        src: vintageThinking6,
        alt: "ANON Fashion Magazine | VINTAGE THINKING",
      },
      {
        src: vintageThinking7,
        alt: "ANON Fashion Magazine | VINTAGE THINKING",
      },
    ],
  },
  {
    title: "L’Officiel Baltic | Dancing Queen",
    artConcept: "LAZAR STEFANIA & WERONIKA KOSIŃSKA",
    stylistMovement: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    model: "ITA LITWINIEC",
    images: [
      { src: lOfficielBaltic5, alt: "L’Officiel Baltic | Dancing Queen" },
      { src: lOfficielBaltic7, alt: "L’Officiel Baltic | Dancing Queen" },
      { src: lOfficielBaltic4, alt: "L’Officiel Baltic | Dancing Queen" },
      { src: lOfficielBaltic8, alt: "L’Officiel Baltic | Dancing Queen" },
      { src: lOfficielBaltic2, alt: "L’Officiel Baltic | Dancing Queen" },
      { src: lOfficielBaltic10, alt: "L’Officiel Baltic | Dancing Queen" },
      { src: lOfficielBaltic9, alt: "L’Officiel Baltic | Dancing Queen" },
      { src: lOfficielBaltic3, alt: "L’Officiel Baltic | Dancing Queen" },
      { src: lOfficielBaltic11, alt: "L’Officiel Baltic | Dancing Queen" },
      { src: lOfficielBaltic1, alt: "L’Officiel Baltic | Dancing Queen" },
    ],
  },
  {
    title: "K MAG | SECRET GEOMETRY",
    artConcept: "LAZAR STEFANIA & WERONIKA KOSIŃSKA",
    stylistMovement: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    model: "DIANA SKOWRON",
    images: [
      { src: kMag25, alt: "K MAG | SECRET GEOMETRY" },
      { src: kMag24, alt: "K MAG | SECRET GEOMETRY" },
      { src: kMag22, alt: "K MAG | SECRET GEOMETRY" },
      { src: kMag26, alt: "K MAG | SECRET GEOMETRY" },
      { src: kMag23, alt: "K MAG | SECRET GEOMETRY" },
      { src: kMag5, alt: "K MAG | SECRET GEOMETRY" },
      { src: kMag27, alt: "K MAG | SECRET GEOMETRY" },
      { src: kMag28, alt: "K MAG | SECRET GEOMETRY" },
      { src: kMag29, alt: "K MAG | SECRET GEOMETRY" },
      { src: kMag7, alt: "K MAG | SECRET GEOMETRY" },
    ],
  },
  {
    title: "WYSOKIE OBCASY | AGATA BUZEK",
    stylist: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    model: "AGATA BUZEK",
    images: [
      { src: wysokieObcasy1, alt: "WYSOKIE OBCASY" },
      { src: wysokieObcasy5, alt: "WYSOKIE OBCASY" },
      { src: wysokieObcasy3, alt: "WYSOKIE OBCASY" },
      { src: wysokieObcasy2, alt: "WYSOKIE OBCASY" },
    ],
  },
  {
    title: "WYSOKIE OBCASY EXTRA | ALEKSANDRA ŻEBROWSKA",
    stylist: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    model: "ALEKSANDRA ŻEBROWSKA",
    images: [
      { src: wysokieObcasyExtra2, alt: "WYSOKIE OBCASY EXTRA" },
      { src: wysokieObcasyExtra5, alt: "WYSOKIE OBCASY EXTRA" },
      { src: wysokieObcasyExtra3, alt: "WYSOKIE OBCASY EXTRA" },
      { src: wysokieObcasyExtra6, alt: "WYSOKIE OBCASY EXTRA" },
    ],
  },
  {
    title: "NASTY Magazine | Rolling",
    artConcept: "LAZAR STEFANIA & JAKUB SZYMAŃSKI",
    stylistMovement: "LAZAR STEFANIA",
    photograph: "JAKUB SZYMAŃSKI",
    models: "ASIA PIWKA & JULIA BORAWSKA",
    images: [
      { src: nastyMagazine14, alt: "NASTY Magazine | Rolling" },
      { src: nastyMagazine8, alt: "NASTY Magazine | Rolling" },
      { src: nastyMagazine3, alt: "NASTY Magazine | Rolling" },
      { src: nastyMagazine10, alt: "NASTY Magazine | Rolling" },
      { src: nastyMagazine9, alt: "NASTY Magazine | Rolling" },
      { src: nastyMagazine18, alt: "NASTY Magazine | Rolling" },
      { src: nastyMagazine17, alt: "NASTY Magazine | Rolling" },
      { src: nastyMagazine16, alt: "NASTY Magazine | Rolling" },
      { src: nastyMagazine23, alt: "NASTY Magazine | Rolling" },
      { src: nastyMagazine24, alt: "NASTY Magazine | Rolling" },
      { src: nastyMagazine22, alt: "NASTY Magazine | Rolling" },
    ],
  },
  {
    title: "NASTY Magazine | The Hunger",
    stylist: "LAZAR STEFANIA",
    photograph: "JAKUB SZYMAŃSKI",
    model: "JULIA BILSKA",
    images: [
      { src: nastyMagazine4, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine1, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine11, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine6, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine20, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine13, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine12, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine2, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine25, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine26, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine28, alt: "NASTY Magazine | The Hunger" },
      { src: nastyMagazine27, alt: "NASTY Magazine | The Hunger" },
    ],
  },
  {
    title: "ANON Fashion Magazine | BEATA + MAGDA",
    stylistMovement: "LAZAR STEFANIA",
    photograph: "JAKUB SZYMAŃSKI",
    models: "BEATA GRABOWSKA & MAG ZIAJOR",
    images: [
      {
        src: anonFashionMagazine5,
        alt: "ANON Fashion Magazine | BEATA + MAGDA",
      },
      {
        src: anonFashionMagazine2,
        alt: "ANON Fashion Magazine | BEATA + MAGDA",
      },
      {
        src: anonFashionMagazine4,
        alt: "ANON Fashion Magazine | BEATA + MAGDA",
      },
      {
        src: anonFashionMagazine3,
        alt: "ANON Fashion Magazine | BEATA + MAGDA",
      },
      {
        src: anonFashionMagazine1,
        alt: "ANON Fashion Magazine | BEATA + MAGDA",
      },
      {
        src: anonFashionMagazine6,
        alt: "ANON Fashion Magazine | BEATA + MAGDA",
      },
      {
        src: anonFashionMagazine7,
        alt: "ANON Fashion Magazine | BEATA + MAGDA",
      },
    ],
  },
  {
    title: "K MAG | Falling at Her Feet",
    artConcept: "LAZAR STEFANIA & WERONIKA KOSIŃSKA",
    stylistMovement: "LAZAR STEFANIA",
    photograph: "WERONIKA KOSIŃSKA",
    models: "WERONIKA KAŹMIERCZAK & OLA KAŹMIERCZAK",
    images: [
      { src: kMag14, alt: "K MAG | Falling at Her Feet" },
      { src: kMag30, alt: "K MAG | Falling at Her Feet" },
      { src: kMag9, alt: "K MAG | Falling at Her Feet" },
      { src: kMag31, alt: "K MAG | Falling at Her Feet" },
      { src: kMag33, alt: "K MAG | Falling at Her Feet" },
      { src: kMag8, alt: "K MAG | Falling at Her Feet" },
      { src: kMag32, alt: "K MAG | Falling at Her Feet" },
      { src: kMag18, alt: "K MAG | Falling at Her Feet" },
      { src: kMag19, alt: "K MAG | Falling at Her Feet" },
      { src: kMag20, alt: "K MAG | Falling at Her Feet" },
      { src: kMag15, alt: "K MAG | Falling at Her Feet" },
      { src: kMag16, alt: "K MAG | Falling at Her Feet" },
      { src: kMag11, alt: "K MAG | Falling at Her Feet" },
    ],
  },
];

const Fashion = () => {
  const [activeGallery, setActiveGallery] = useState(null);

  useEffect(() => {
    const masonryFs = new Macy({
      container: ".column.--fashion",
      mobileFirst: true,
      waitForImages: true,
      columns: 2,
      margin: {
        x: 4,
        y: 4,
      },
      breakAt: {
        757: {
          margin: {
            x: 14,
            y: 14,
          },
          columns: 3,
        },
        1440: {
          margin: {
            x: 14,
            y: 14,
          },
          columns: 4,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (activeGallery !== null) {
      new Macy({
        container: `#sub-gallery-column-${activeGallery}`,
        mobileFirst: true,
        waitForImages: true,
        columns: 2,
        margin: {
          x: 4,
          y: 4,
        },
        breakAt: {
          757: {
            margin: {
              x: 14,
              y: 14,
            },
            columns: 3,
          },
        },
      });
    }
  }, [activeGallery]);

  const handleGalleryItemClick = (index) => {
    setActiveGallery(index);
  };

  const handleBackClick = () => {
    setActiveGallery(null);
  };

  return (
    <section className="content --portfolio --orange-bg">
      <header className="header">
        <section className="header__wrapper">
          <a className="header__back" href="/portfolio" title="Portfolio">
            <img src={leftArrow} alt="arrow" />
            <img src={portfolioIcon} alt="Portfolio" />
          </a>
          <span className="header__portfolio-item">
            <img src={fashionIcon} alt="Fashion" />
          </span>
        </section>
      </header>
      <section className="gallery">
        <ul
          className={`column --fashion ${
            activeGallery !== null ? "--hidden" : ""
          }`}
        >
          {galleryItems.map((item, index) => (
            <li
              className="item"
              key={index}
              onClick={() => handleGalleryItemClick(index)}
            >
              <img src={item.src} alt={item.alt} />
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
        {activeGallery !== null && (
          <section className="sub-gallery --active">
            <div className="sub-gallery__header" onClick={handleBackClick}>
              <section className="header__wrapper">
                <span className="header__back">
                  <img src={leftArrow} alt="arrow" />
                  <img src={fashionIcon} alt="Portfolio" />
                </span>
              </section>
            </div>
            <ul className="sub-gallery__wrapper">
              <li className="sub-gallery__item">
                <div className="sub-gallery__top">
                  <div className="top__img">
                    <img
                      src={subGalleryItems[activeGallery].images[0].src}
                      alt={subGalleryItems[activeGallery].images[0].alt}
                    />
                  </div>
                  <div className="top__txt">
                    <h2>{subGalleryItems[activeGallery].title}</h2>
                    <span>stylist</span>
                    <p>{subGalleryItems[activeGallery].stylist}</p>
                    <span>photograph</span>
                    <p>{subGalleryItems[activeGallery].photograph}</p>
                    {subGalleryItems[activeGallery].model && (
                      <>
                        <span>model</span>
                        <p>{subGalleryItems[activeGallery].model}</p>
                      </>
                    )}
                    {subGalleryItems[activeGallery].models && (
                      <>
                        <span>models</span>
                        <p>{subGalleryItems[activeGallery].models}</p>
                      </>
                    )}
                    {subGalleryItems[activeGallery].makeupHair && (
                      <>
                        <span>makeup & hair</span>
                        <p>{subGalleryItems[activeGallery].makeupHair}</p>
                      </>
                    )}
                    {subGalleryItems[activeGallery].stylistMovement && (
                      <>
                        <span>stylist & movement</span>
                        <p>{subGalleryItems[activeGallery].stylistMovement}</p>
                      </>
                    )}
                    {subGalleryItems[activeGallery].artConcept && (
                      <>
                        <span>art concept</span>
                        <p>{subGalleryItems[activeGallery].artConcept}</p>
                      </>
                    )}
                  </div>
                </div>
                <ul
                  className="sub-gallery__column"
                  id={`sub-gallery-column-${activeGallery}`}
                >
                  {subGalleryItems[activeGallery].images
                    .slice(1)
                    .map((image, idx) => (
                      <li className="item" key={idx}>
                        <img src={image.src} alt={image.alt} />
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
          </section>
        )}
      </section>
    </section>
  );
};

export default Fashion;
