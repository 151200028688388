import React from "react";
import PropTypes from "prop-types";

const Footer = ({ style }) => {
  return (
    <footer className="footer" style={style}>
      <section className="footer__wrapper">
        <span>Copyright &copy; 2022 lazarstefania.com </span>
        <a href="https://jasnezmodrym.pl/">designed by jasnezmodrym.pl</a>
      </section>
    </footer>
  );
};

Footer.propTypes = {
  style: PropTypes.object,
};

Footer.defaultProps = {
  style: {},
};

export default Footer;
