// src/components/FashionShow.js

import React from "react";
import { Link } from "react-router-dom";
import leftArrow from "../assets/img/left-arrow.png";
import portfolioIcon from "../assets/portfolio/portfolio_lazar 1.png";
import fashionShowIcon from "../assets/img/fashionshow_lazar.png";

const FashionShow = () => {
  return (
    <section className="content --portfolio --commercial">
      <header className="header --fashion">
        <section className="header__wrapper">
          <Link className="header__back" to="/portfolio" title="Portfolio">
            <img src={leftArrow} alt="arrow" />
            <img src={portfolioIcon} alt="Portfolio" />
          </Link>
          <span className="header__portfolio-item">
            <img src={fashionShowIcon} alt="Fashion Show" />
          </span>
        </section>
      </header>
      <section className="commercial-video">
        <div className="commercial-video__wrapper">
          <div className="commercial-video__item">
            <span>KTW FASHION WEEK | ALL EDITIONS</span>
            <span>DIRECTOR’S ASSISTANT AND CHEF BACKSTAGE</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/u3ND0A_0HsI"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>TOMASZ OSSOLIŃSKI FASHION SHOW</span>
            <span>DIRECTOR’S ASSISTANT AND CHEF BACKSTAGE</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/8IuXtetYwIM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>ODIO x PIECZARKOWSKI AW15/16</span>
            <span>DIRECTOR</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/sF5xMFm3-sA"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    </section>
  );
};

export default FashionShow;
