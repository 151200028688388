import React from "react";

const About = () => {
  return (
    <section className="content --pink-bg">
      <section className="about">
        <article className="about__column">
          <p>
            I studied textiles at the University of Technology and Humanities in
            Bielsko-Biała, as well as Theater and Art Promotion in Łódź. I am
            mainly <span className="bold">fashion stylist</span> and{" "}
            <span className="bold">costume designer (commercial, movie)</span>,
            and also I work as a <span className="bold">creative director</span>
            .
          </p>
          <p>
            I was the second costume designer in the film "Pardon" by Jan Jakub
            Kolski, which was awarded for costumes at the Polish Film Festival
            in Gdynia in 2018. I also made a feature debut - the film „Speedway"
            in 2020, directed by Dorota Kędzierzawska. I also created costumes
            for the film "Prophet", which premiered in November 2022. I was the
            second costume designer in the tv series "Klara" by Łukasz Jaworski,
            which was broadcasted in 2024, on „player.pl” - polish streaming
            pla\orm.
          </p>
          <p>
            As a costume designer in advertising, I worked for brands such as
            Lotto, Neflix, ROSSMANN, Zatecky, Delio, Żołądkowa Gorzka, Beckers,
            Maślanka Mrągowska, Allegro Smart, Kaufland, Komfort, Amic Energy,
            Discovery Channel, Seni Polska, Tax Solidarity Eu and many others.
          </p>
          <p>
            As a stylist and art director I work on photoshoots for the most
            popular magazines, including: K Mag, Harpers Bazaar, L'Officiel,
            Wysokie Obcasy Extra, Zwierciadło, Latest, Contributor and Nasty
            Magazine.
          </p>
          <p>
            During photoshoots for magazines or music videos - I prepared
            costume/styling concepts for Mela Koteluk, Julia Pietrucha, Majka
            Jeżowska, Eliza Rycembel, Agata Buzek, Agata Turkot, Maciek
            Zakościelny, Paweł Małaszyński and many others.
          </p>
          <p>
            I was a co-creator of Fashion Philosophy Fashion Week Poland (all
            editions) and KTW Fashion Week (all editions) (ELLE Style Award
            2019).
          </p>
        </article>
        <article className="about__column">
          <p>
            I directed fashion shows for brands such as: BLIKVANGER, NATASHA
            PAVLUCHENKO, MMC STUDIO, ODIO TEES, BIZUU, TOMASZ OSSOLIŃSKI, SOWIK,
            JAROSŁAW EWERT and others.
          </p>
          <p>
            <strong>To the point:</strong>
          </p>
          <p>
            I believe in the power of detail and the idea of communication
            throughout a costume.
          </p>
          <p>
            I love to create the world around character, by mixing textures,
            form, motion possibility, and pa^erns to present my unique vision.
          </p>
          <div className="about__video">
            <span>FASHION STYLIST & COSTUME DESIGNER</span>
            <span>COSTUME DESIGNER</span>
            <iframe
              src="https://player.vimeo.com/video/698211196?h=9cde2b93a6"
              width="340"
              height="280"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="contact">
            <span>Contact</span>
            <a href="tel:662762491">
              <strong>T </strong>662 762 491
            </a>
            <a href="mailto:kontakt@lazarstefania.com">
              <strong>E </strong>kontakt@lazarstefania.com
            </a>
            <a href="mailto:lazar.stefania@gmail.com">
              <strong>E </strong>lazar.stefania@gmail.com
            </a>
          </div>
        </article>
      </section>
    </section>
  );
};

export default About;
