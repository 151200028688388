import React from "react";
import logo from "../assets/img/logo_stefanialazar_R 2.png";
import aboutImage from "../assets/portfolio/about_lazar 1.png";
import instagramImage from "../assets/portfolio/instagram_lazar 1.png";
import portfolioImage from "../assets/portfolio/portfolio_lazar 1.png";

const Header = ({ isAboutPage }) => {
  return (
    <header className={`header ${isAboutPage ? "--pink-bg" : ""}`}>
      <section className="header__wrapper">
        <div className="logo">
          <div className="logo__container">
            <a href="/" className="logo__link">
              <img src={logo} alt="Lazar Stefania" />
            </a>
          </div>
        </div>
        <nav role="navigation">
          <div id="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
              <li>
                <a href="/about" title="About">
                  <img src={aboutImage} alt="About" />
                </a>
              </li>
              <li>
                <a href="https://instagram.com/lazar.stefania?utm_medium=copy_link">
                  <img src={instagramImage} alt="Instagram" />
                </a>
              </li>
              <li>
                <a href="/portfolio" title="Portfolio">
                  <img src={portfolioImage} alt="Portfolio" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </section>
    </header>
  );
};

export default Header;
