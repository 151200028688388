import React, { useEffect } from "react";
import Macy from "macy";
import leftArrow from "../assets/img/left-arrow.png";
import portfolioIcon from "../assets/portfolio/portfolio_lazar 1.png";
import commercialIcon from "../assets/portfolio/commercial_lazar 1.png";

import ca1 from "../assets/gallery/CA.1.png";
import ca2 from "../assets/gallery/CA.2.png";
import ca3 from "../assets/gallery/CA.3.png";
import ca4 from "../assets/gallery/CA.4.png";
import ca5 from "../assets/gallery/CA.5.png";
import ca6 from "../assets/gallery/CA.6.png";
import ca7 from "../assets/gallery/CA.7.png";

const WysokieObcasyExtra = () => {
  useEffect(() => {
    const masonry = new Macy({
      container: ".column.--commercial",
      mobileFirst: true,
      waitForImages: true,
      columns: 2,
      margin: {
        x: 4,
        y: 4,
      },
      breakAt: {
        757: {
          margin: {
            x: 14,
            y: 14,
          },
          columns: 3,
        },
      },
    });
  }, []);

  return (
    <section className="content --portfolio --commercial --orange-bg">
      <header className="header">
        <section className="header__wrapper">
          <a className="header__back" href="/portfolio" title="Portfolio">
            <img src={leftArrow} alt="arrow" />
            <img src={portfolioIcon} alt="Portfolio" />
          </a>
          <a href="/commercial" className="header__portfolio-item">
            <img src={commercialIcon} alt="Commercial" />
          </a>
          <div className="header__description">
            <span>C&A x Wysokie Obcasy Extra</span>
          </div>
        </section>
      </header>
      <section className="gallery">
        <ul className="column --commercial">
          <li className="item">
            <img src={ca1} alt="WYSOKIE OBCASY EXTRA | GIFT IDEAS PHOTOSHOOT" />
          </li>
          <li className="item">
            <img src={ca5} alt="WYSOKIE OBCASY EXTRA | GIFT IDEAS PHOTOSHOOT" />
          </li>
          <li className="item">
            <img src={ca3} alt="WYSOKIE OBCASY EXTRA | GIFT IDEAS PHOTOSHOOT" />
          </li>
          <li className="item">
            <img src={ca7} alt="WYSOKIE OBCASY EXTRA | GIFT IDEAS PHOTOSHOOT" />
          </li>
          <li className="item">
            <img src={ca4} alt="WYSOKIE OBCASY EXTRA | GIFT IDEAS PHOTOSHOOT" />
          </li>
          <li className="item">
            <img src={ca6} alt="WYSOKIE OBCASY EXTRA | GIFT IDEAS PHOTOSHOOT" />
          </li>
        </ul>
      </section>
    </section>
  );
};

export default WysokieObcasyExtra;
