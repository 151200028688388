import React from "react";
import { Link } from "react-router-dom";
import leftArrow from "../assets/img/left-arrow.png";
import portfolioIcon from "../assets/portfolio/portfolio_lazar 1.png";
import commercialIcon from "../assets/portfolio/commercial_lazar 1.png";
import hairCampaign3 from "../assets/gallery/HAIR_CAMPAIGN-3.png";
import nunthatchCampaign4 from "../assets/gallery/NUNTHATCH_CAMPAIGN-4.png";
import pepe1 from "../assets/gallery/PEPE.1.png";
import ca2 from "../assets/gallery/CA.2.png";
// commercial.js

const Commercial = () => {
  document.addEventListener("DOMContentLoaded", function () {
    new Macy({
      container: ".column.--commercial",
      mobileFirst: true,
      waitForImages: true,
      columns: 2,
      margin: {
        x: 4,
        y: 4,
      },
      breakAt: {
        757: {
          margin: {
            x: 14,
            y: 14,
          },
          columns: 3,
        },
        1440: {
          margin: {
            x: 14,
            y: 14,
          },
          columns: 4,
        },
      },
    });
  });
  return (
    <section className="content --portfolio --commercial --orange-bg">
      <header className="header">
        <section className="header__wrapper">
          <Link className="header__back" to="/portfolio" title="Portfolio">
            <img src={leftArrow} alt="arrow" />
            <img src={portfolioIcon} alt="Portfolio" />
          </Link>
          <span className="header__portfolio-item">
            <img src={commercialIcon} alt="Commercial" />
          </span>
        </section>
      </header>
      <section className="commercial-top">
        <div className="commercial-top__wrapper">
          <Link to="/patrizia-pepe" className="commercial-top__item">
            <div>
              <span>PATRIZIA PEPE</span>
              <span>x</span>
              <span>Wysokie Obcasy Extra</span>
            </div>
            <img src={pepe1} alt="PATRIZIA PEPE x Wysokie Obcasy Extra" />
          </Link>
          <Link to="/wysokie-obcasy-extra" className="commercial-top__item">
            <div>
              <span>C&A</span>
              <span>x</span>
              <span>Wysokie Obcasy Extra</span>
            </div>
            <img src={ca2} alt="C&A x Wysokie Obcasy Extra" />
          </Link>
          <Link to="/hair-campaign" className="commercial-top__item">
            <div>
              <span>HAIR CAMPAIGN</span>
              <span>by PATRYK BARTYNA</span>
            </div>
            <img src={hairCampaign3} alt="HAIR CAMPAIGN by PATRYK BARTYNA" />
          </Link>
          <Link to="/nunthatch-campaign" className="commercial-top__item">
            <div>
              <span>NUNTHATCH CAMPAIGN</span>
            </div>
            <img src={nunthatchCampaign4} alt="NUNTHATCH CAMPAIGN" />
          </Link>
        </div>
      </section>
      <section className="commercial-video">
        <div className="commercial-video__wrapper">
          <div className="commercial-video__item">
            <span>REBEL MOON | JOANNA JĘDRZEJCZYK</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/930n1a52QeA?si=AGVGEbx-yQn7Eino"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>BECKERS</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/IFkUHNh0pFk?si=m24asNeSIATXOcO7"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>ZATECKY | WIOLKA & RUTEK | ROCZNA PENSJA</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/HwpgmcHbF9E?si=21RIsbEKJJplSfKq"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/sRKo99Oco4M?si=8pKMqXEGrDYl9VNl"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/bCFAvencBd0?si=2_3xpGfM5R4U0xhN"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Sq33UVDph6U?si=NFks1Gv8FWF2896Y"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>ZATECKY | WIOLKA & RUTEK | MAMY TU PEŁEN LUZ</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/fAxs0l--xyE?si=1zf1B8LlNRGqAfkT"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/767j58rNbwQ?si=MLYQlxazDy9kRgnv"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/cHkjwpf_-fU?si=42YRpt0zxR9Yq6G9"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/7aWJZ_cHExQ?si=gOvuciLFBih_yVvc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>ROSSMANN</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/jAnaESIxUJ8?si=9xRS6rxQ130N0jsQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/cn8ZV50nVkg?si=eOqy1ldmJmBnU3aS"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>DELIO</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Nl4L0rHeOhY?si=-lz1Yfv3FoKnejO4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/tAplaDPK-EU?si=wdVkc0-jUYasbAG3"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/EozJiG7d978?si=Gou_Nv2HC7Uoxlow"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>MAŚLANKA MRĄGOWSKA</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/5ynoQ4HCu9A?si=p2_kJd3PUFgoNQvW"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>KAUFLAND - JESIENNA LOTERIA</span>
            <iframe
              src="https://player.vimeo.com/video/761426990?h=223ec47ead"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>TADALAFIL MAXIGRA</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/SF-ui_uE-Pw?si=zjmMH47yNXY94hju"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>KOMFORT</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/iiBp5a30R-o"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>KOMFORT</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/b8un1fDracI"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>ARTEGO GLOBAL</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/LiVfgEtxIvI"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>VINTAGECONCEPT</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/soTPapeharw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>ANIA LEON | TAŃCZ</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/8uc19u0yWCE?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>LOTTO</span>
            <iframe
              src="https://player.vimeo.com/video/510276917?h=1997b7156d"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>ALLEGRO SMART</span>
            <iframe
              src="https://www.youtube.com/embed/m-f_ay3Lro8?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              src="https://www.youtube.com/embed/MVWjS_nF4cY?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span>SENI LADY</span>
            <iframe
              src="https://www.youtube.com/embed/jMKVavyDI4w?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Commercial;
