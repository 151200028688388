import React, { useEffect } from "react";
import Macy from "macy";
import leftArrow from "../assets/img/left-arrow.png";
import portfolioIcon from "../assets/portfolio/portfolio_lazar 1.png";
import commercialIcon from "../assets/portfolio/commercial_lazar 1.png";

import nunthatch1 from "../assets/gallery/NUNTHATCH_CAMPAIGN-1.png";
import nunthatch7 from "../assets/gallery/NUNTHATCH_CAMPAIGN-7.png";
import nunthatch4 from "../assets/gallery/NUNTHATCH_CAMPAIGN-4.png";
import nunthatch9 from "../assets/gallery/NUNTHATCH_CAMPAIGN-9.png";
import nunthatch8 from "../assets/gallery/NUNTHATCH_CAMPAIGN-8.png";
import nunthatch3 from "../assets/gallery/NUNTHATCH_CAMPAIGN-3.png";
import nunthatch5 from "../assets/gallery/NUNTHATCH_CAMPAIGN-5.png";

const NunthatchCampaign = () => {
  useEffect(() => {
    const masonry = new Macy({
      container: ".column.--commercial",
      mobileFirst: true,
      waitForImages: true,
      columns: 2,
      margin: {
        x: 4,
        y: 4,
      },
      breakAt: {
        757: {
          margin: {
            x: 14,
            y: 14,
          },
          columns: 3,
        },
      },
    });
  }, []);

  return (
    <section className="content --portfolio --commercial --orange-bg">
      <header className="header">
        <section className="header__wrapper">
          <a className="header__back" href="/portfolio" title="Portfolio">
            <img src={leftArrow} alt="arrow" />
            <img src={portfolioIcon} alt="Portfolio" />
          </a>
          <a href="/commercial" className="header__portfolio-item">
            <img src={commercialIcon} alt="Commercial" />
          </a>
          <div className="header__description">
            <span>NUNTHATCH CAMPAIGN</span>
          </div>
        </section>
      </header>
      <section className="gallery">
        <ul className="column --commercial">
          <li className="item">
            <img src={nunthatch1} alt="NUNTHATCH CAMPAIGN" />
          </li>
          <li className="item">
            <img src={nunthatch7} alt="NUNTHATCH CAMPAIGN" />
          </li>
          <li className="item">
            <img src={nunthatch4} alt="NUNTHATCH CAMPAIGN" />
          </li>
          <li className="item">
            <img src={nunthatch9} alt="NUNTHATCH CAMPAIGN" />
          </li>
          <li className="item">
            <img src={nunthatch8} alt="NUNTHATCH CAMPAIGN" />
          </li>
          <li className="item">
            <img src={nunthatch3} alt="NUNTHATCH CAMPAIGN" />
          </li>
          <li className="item">
            <img src={nunthatch5} alt="NUNTHATCH CAMPAIGN" />
          </li>
        </ul>
      </section>
    </section>
  );
};

export default NunthatchCampaign;
