import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Commercial from "./components/Commercial";
import Movie from "./components/Movie";
import FashionShow from "./components/FashionShow";
import Fashion from "./components/Fashion";
import HairCampaign from "./components/HairCampaign";
import NunthatchCampaign from "./components/NunthatchCampaign";
import PatriziaPepe from "./components/PatriziaPepe";
import WysokieObcasyExtra from "./components/WysokieObcasyExtra";

function App() {
  return (
    <Router>
      <div className="App">
        <CustomHeader />
        <Routes>
          <Route path="/" element={<Gallery />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/commercial" element={<Commercial />} />
          <Route path="/movie" element={<Movie />} />
          <Route path="/fashion_show" element={<FashionShow />} />
          <Route path="/fashion" element={<Fashion />} />
          <Route path="/hair-campaign" element={<HairCampaign />} />
          <Route path="/nunthatch-campaign" element={<NunthatchCampaign />} />
          <Route path="/patrizia-pepe" element={<PatriziaPepe />} />
          <Route
            path="/wysokie-obcasy-extra"
            element={<WysokieObcasyExtra />}
          />
        </Routes>
        <CustomFooter />
      </div>
    </Router>
  );
}

const CustomHeader = () => {
  const location = useLocation();
  const isPortfolioPage = location.pathname === "/portfolio";
  const isCommercialPage = location.pathname === "/commercial";
  const isAboutPage = location.pathname === "/about";
  const isFashionShowPage = location.pathname === "/fashion_show";
  const isMoviePage = location.pathname === "/movie";
  const isFashionPage = location.pathname === "/fashion";
  const isHairCampaignPage = location.pathname === "/hair-campaign";
  const isNunthatchCampaignPage = location.pathname === "/nunthatch-campaign";
  const isPatriziaPepe = location.pathname === "/patrizia-pepe";
  const isWysokieObcasyExtraPage =
    location.pathname === "/wysokie-obcasy-extra";
  return (
    !isMoviePage &&
    !isFashionShowPage &&
    !isPortfolioPage &&
    !isCommercialPage &&
    !isFashionPage &&
    !isHairCampaignPage &&
    !isNunthatchCampaignPage &&
    !isPatriziaPepe &&
    !isWysokieObcasyExtraPage && <Header isAboutPage={isAboutPage} />
  );
};

const CustomFooter = () => {
  const location = useLocation();
  const isPortfolioPage = location.pathname === "/portfolio";
  const isCommercialPage = location.pathname === "/commercial";
  const isAboutPage = location.pathname === "/about";
  const isFashionShowPage = location.pathname === "/fashion_show";
  const isMoviePage = location.pathname === "/movie";
  const isHairCampaignPage = location.pathname === "/hair-campaign";
  const isNunthatchCampaignPage = location.pathname === "/nunthatch-campaign";
  const isPatriziaPepe = location.pathname === "/patrizia-pepe";
  const isWysokieObcasyExtraPage =
    location.pathname === "/wysokie-obcasy-extra";
  let footerStyle = {};

  if (
    isCommercialPage ||
    isFashionShowPage ||
    isMoviePage ||
    isHairCampaignPage ||
    isNunthatchCampaignPage ||
    isPatriziaPepe ||
    isWysokieObcasyExtraPage
  ) {
    footerStyle = { backgroundColor: "#ffffff" };
  } else if (isAboutPage) {
    footerStyle = { backgroundColor: "#ed62a3" };
  }

  return !isPortfolioPage && <Footer style={footerStyle} />;
};

export default App;
