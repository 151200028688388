// src/components/Movie.js

import React from "react";
import { Link } from "react-router-dom";
import leftArrow from "../assets/img/left-arrow.png";
import portfolioIcon from "../assets/portfolio/portfolio_lazar 1.png";
import movieIcon from "../assets/img/movie_lazar 2.png";

const Movie = () => {
  return (
    <section className="content --portfolio --commercial">
      <header className="header --fashion">
        <section className="header__wrapper">
          <Link className="header__back" to="/portfolio" title="Portfolio">
            <img src={leftArrow} alt="arrow" />
            <img src={portfolioIcon} alt="Portfolio" />
          </Link>
          <span className="header__portfolio-item">
            <img src={movieIcon} alt="Movie" />
          </span>
        </section>
      </header>
      <section className="commercial-video">
        <div className="commercial-video__wrapper">
          <div className="commercial-video__item">
            <span className="commercial-video__aside">
              2nd COSTUME DESIGNER
            </span>
            <span>KLARA | MINI SERIES</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Hi6YBgmHjuQ?si=1dFqXbTebyeFGi9g"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span className="commercial-video__aside">
              2nd COSTUME DESIGNER
            </span>
            <span>PROROK</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/AkW0URFiIXo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span className="commercial-video__aside">COSTUME DESIGNER</span>
            <span>CIENIE WIEŻY</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/ZGtN9p1BwP4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span className="commercial-video__aside">
              2nd COSTUME DESIGNER
            </span>
            <span>UŁASKAWIENIE</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/v6MemjFTCkU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className="commercial-video__item">
            <span className="commercial-video__aside">COSTUME DESIGNER</span>
            <span>ŻUŻEL</span>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/xY2kpDutEY4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Movie;
