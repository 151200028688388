import React, { useEffect } from "react";
import Macy from "macy";
import leftArrow from "../assets/img/left-arrow.png";
import portfolioIcon from "../assets/portfolio/portfolio_lazar 1.png";
import commercialIcon from "../assets/portfolio/commercial_lazar 1.png";

import pepe1 from "../assets/gallery/PEPE.1.png";
import pepe2 from "../assets/gallery/PEPE.2.png";
import pepe3 from "../assets/gallery/PEPE.3.png";
import pepe4 from "../assets/gallery/PEPE.4.png";
import pepe5 from "../assets/gallery/PEPE.5.png";
import pepe6 from "../assets/gallery/PEPE.6.png";

const PatriziaPepe = () => {
  useEffect(() => {
    const masonry = new Macy({
      container: ".column.--commercial",
      mobileFirst: true,
      waitForImages: true,
      columns: 2,
      margin: {
        x: 4,
        y: 4,
      },
      breakAt: {
        757: {
          margin: {
            x: 14,
            y: 14,
          },
          columns: 3,
        },
      },
    });
  }, []);

  return (
    <section className="content --portfolio --commercial --orange-bg">
      <header className="header">
        <section className="header__wrapper">
          <a className="header__back" href="/portfolio" title="Portfolio">
            <img src={leftArrow} alt="arrow" />
            <img src={portfolioIcon} alt="Portfolio" />
          </a>
          <a href="/commercial" className="header__portfolio-item">
            <img src={commercialIcon} alt="Commercial" />
          </a>
          <div className="header__description">
            <span>PATRIZIA PEPE x Wysokie Obcasy Extra</span>
          </div>
        </section>
      </header>
      <section className="gallery">
        <ul className="column --commercial">
          <li className="item">
            <img src={pepe2} alt="HAIR CAMPAIGN ARTEGO GLOBAL SHAPE OF LOVE" />
          </li>
          <li className="item">
            <img src={pepe3} alt="HAIR CAMPAIGN ARTEGO GLOBAL SHAPE OF LOVE" />
          </li>
          <li className="item">
            <img src={pepe1} alt="HAIR CAMPAIGN ARTEGO GLOBAL SHAPE OF LOVE" />
          </li>
          <li className="item">
            <img src={pepe6} alt="HAIR CAMPAIGN ARTEGO GLOBAL SHAPE OF LOVE" />
          </li>
          <li className="item">
            <img src={pepe5} alt="HAIR CAMPAIGN ARTEGO GLOBAL SHAPE OF LOVE" />
          </li>
          <li className="item">
            <img src={pepe4} alt="HAIR CAMPAIGN ARTEGO GLOBAL SHAPE OF LOVE" />
          </li>
        </ul>
      </section>
    </section>
  );
};

export default PatriziaPepe;
